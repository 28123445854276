.card-data {
  max-width: 460px;
  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;

  background-color: rgb(244, 83, 132);
  border-radius: 16px;
  
  z-index: 0;

  margin: -16px -15px 0 -15px;

  &__info-container {
    display: flex;
    flex-direction: column;
    
    color: #FFF;
    font-size: 14px;
  }

  &__info-data {
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
  }
}

@media (max-width: 500px) {
  .card-data {
    height: 70px;
    width: 100%;

    margin: -16px 0 0 0;
  }
}

@media (max-width: 400px) {
  .card-data {
    height: 70px;
    width: 100vw;
  }
}

