.ready-order-popup {
  &__link {
    font-weight: 700;
    cursor: pointer;
    color: #4661C4;
  }

  &__title {
    text-align: center;

    margin: 0 0 16px;
  }

  &__description {
    font-size: 18px;
    text-align: center;

    margin: 0;
  }
}

@media (max-width: 768px) {
  .ready-order-popup {
    width: 100%;
    height: 100%;
  }
}
