.testograf-popup {
  &__iframe {
    width: 100%;
    height: 600px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
  }
}

@media (max-width: 768px) {
  .testograf-popup {
    width: 100%;
    height: 100%;
  }
}
