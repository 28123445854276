.too-many-attempts {
  text-align: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    font-weight: 500;

    margin: 0 0 16px;
  }

  &__icon {
    width: 100px;
    height: 100px;
    max-width: 100px;

    margin: 0 0 16px;
  }

  &__text {
    text-align: center;
    font-weight: 500;

    margin: 0;
  }
}

@media (max-width:768px) {
  .too-many-attempts {
    width: 100%;
    height: 100%;

    justify-content: flex-start;

    padding: 60px 0 0;
  }
}
