@font-face {
  font-family: 'Circe';
  src: url('../../../../../../../../assets/fonts/Circe-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.message {
  &__question-icon {
    margin-left: 5px;
    margin-top: 5px;
  }
}

.history-item {
  width: auto;
  background-color: #FFFFFF;
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__order-sum {
    font-size: 18px;
    line-height: 26px;
    color: #B0B7D3;

    margin: 0 0 16px;

    &_bold {
      font-weight: 700;
      color: #4661C4;
    }
  }

  &__order-sum-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__payment-type {
    &_desktop {
      display: block;
      width: 140px;
    }

    &_mobile {
      display: none;
    }
  }

  &__title {
    font-family: 'Circe', serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0;
    text-align: left;
  }

  &__data-title {
    font-family: 'Circe', serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #6F6F76;
  }

  &__line {
    width: 100%;
    position: absolute;
    border-bottom: 1px solid #E8EBF6;
    top: 20px;
  }

  &__number {
    width: 200px;
  }

  &__data-value {
    font-family: 'Circe', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: #30303A;
    margin-top: 12px;

    &_colored {
      color: #4661C4;
    }

    &_bold {
      font-weight: 700;
    }
  }

  &__description {
    position: relative;
    justify-content: space-between;
    display: flex;
  }

  &__status-container {
    &_desktop {
      display: block;
    }

    &_mobile {
      display: none;
    }
  }
}

.payments-history {
  transition: opacity 0.3s;

  opacity: 1;

  &__button-container {
    display: none;

    .button {
      background-color: #fff;

      &__text {
        color: #000;
      }

      &__container {
        flex-direction: row-reverse;
      }

      &__icon {
        margin: 0 0 0 4px;
        width: 15px;
      }
    }
  }

  &_hide {
    opacity: 0;
  }

  &__status-notification {
    text-align: center;
    color: #40C179;
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-radius: 16px;
    border: 1px solid #40C179;
    padding: 20px;
    gap: 16px;

    img {
      margin-right: 16px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #30303A;
    margin: 0 0 10px;
  }
}

@media (max-width: 900px) {
  .history-item {
    &__payment-type {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
        color: #30303A;
        font-size: 12px;
      }
    }

    &__status-container {
      &_desktop {
        display: none;
      }

      &_mobile {
        gap: 10px;
        justify-content: flex-start;
        margin-bottom: 15px;
        align-items: center;
        display: flex;
      }
    }
  }
}

@media (max-width: 560px) {
  .payments-history {

    &__button-container {
      text-align: center;
      display: block;
    }

    &__order-sum {
      font-size: 16px;
    }

    &__status-notification {
      font-size: 12px;
      padding: 5px;
      border-radius: 8px;
      align-items: center;
      gap: 8px;

      img {
        margin-right: 8px;
      }
    }
  }

  .history-item {
    gap: 0;
    padding: 14px;

    &__data-value {
      font-size: 12px;
    }

    &__data-title {
      font-size: 8px;
    }

    &__title {
      font-size: 16px;
    }

    &__number {
      width: 135px;
    }

    &__payment-type {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
        color: #30303A;
        font-size: 8px;
      }
    }

    &__status-container {
      &_desktop {
        display: none;
      }

      &_mobile {
        gap: 10px;
        justify-content: flex-start;
        margin-bottom: 0;
        align-items: center;
        display: flex;
      }
    }
  }
}

@media (max-width: 420px) {
  .history-item {
    &__order-sum {
      font-size: 14px;
    }
  }
}
