.money-transfer-button {
  display: flex;
  align-items: center;

  margin: 0 0 24px;

  &__container {
    max-width: 220px;

    display: flex;
    align-items: center;

    background-color: #fff;
    border-radius: 12px;

    cursor: pointer;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

    padding: 9.5px 12px;

    &:hover {
      .money-transfer-button__title {
        text-decoration-color: #4661C4;
        color: #4661C4;
      }

      .money-transfer-button__icon {
        background: url('./assets/images/hoverRub.svg') no-repeat 50% 50%;
      }
    }
  }

  &__icon {
    min-width: 48px;
    height: 48px;
    background: url('./assets/images/rub.svg') no-repeat 50%/cover;

    transition: all 0.3s ease-in;

    margin-right: 12px;
  }

  &__title {
    transition: all 0.3s ease-in;
    text-decoration-color: transparent;
    text-decoration-line: underline;

    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
    color: #6F6F76;

    margin: 0;
  }
}

@media (max-width: 620px) {
  .money-transfer-button {
    width: 100%;

    &__container {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .money-transfer-button {
    margin: 0 0 16px;
  }
}