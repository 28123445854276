.message {
  &__question-icon {
    margin-left: 5px;
    margin-top: 5px;
  }
}

.pull-basket {
  transition: opacity 0.3s;

  opacity: 1;

  &_mobile-row {
    display: none;
  }

  &__table-head {
    &_desktop {
      border-bottom: 1px solid #E8EBF6;

      display: table-header-group;
    }
  }

  &__is-changed {
    font-size: 12px;
    color: #4661C4;
    font-weight: 400;
  }

  &__position-is-changed {
    font-size: 10px;
    color: #4661C4;
    display: none;
    margin-left: 10px;
    font-weight: 400;
  }

  &__button-container {
    display: none;

    .button {
      background-color: #fff;

      &__text {
        color: #000;
      }

      &__container {
        flex-direction: row-reverse;
      }

      &__icon {
        margin: 0 0 0 4px;
        width: 15px;
      }
    }
  }

  &__status {
    width: 200px;
    height: 81px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  &_hide {
    opacity: 0;
  }

  &_mobile {
    display: none;
  }

  &_desktop {
    display: block;
  }

  &__status-notification {
    text-align: center;
    color: #40C179;
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-radius: 16px;
    border: 1px solid #40C179;
    padding: 20px;
    gap: 16px;

    img {
      margin-right: 16px;
    }
  }

  &__card-title-container {
    &_desktop {
      display: flex;
    }

    &_mobile {
      display: none;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #30303A;

    margin: 0 0 10px;
  }

  &__history {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__order {
    border-radius: 16px;

    opacity: 0;

    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: 0 4px 20px rgba(31, 34, 41, 0);
    cursor: pointer;

    transition: all 0.4s linear;

    padding: 24px;
    margin-bottom: 16px;

    &:hover {
      border: 1px solid #B0B7D3;
      box-shadow: 0 4px 20px rgba(31, 34, 41, 0.1);
    }

    &_active {
      opacity: 1;
    }
  }

  &__id-and-date {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #30303A;

    margin: 0 0 4px;
  }

  &__order-sum-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__order-sum {
    font-size: 18px;
    line-height: 26px;
    color: #B0B7D3;

    margin: 0 0 16px;

    &_bold {
      font-weight: 700;
      color: #4661C4;
    }
  }

  &__cards-container {
    width: 100%;

    border-collapse: collapse;
  }

  &__cards-number {
    width: 150px;

    font-size: 12px;
    line-height: 18px;
    color: #30303A;

    margin: 0 0 4px;

    &_red {
      color: #F45384;
    }
  }

  &__cards-title {
    width: 120px;

    font-size: 12px;
    line-height: 18px;
    color: #30303A;

    margin: 0 0 4px;
  }


  &__type-title {
    font-size: 18px;
    font-weight: 700;
    color: #1D1D2D;

    margin: 10px 0;
  }

  &__card {
    height: 52px;

    border-bottom: 1px solid #E8EBF6;
  }

  &__card-id {
    font-size: 18px;
    line-height: 26px;
    color: #30303A;

    padding-left: 10px;

    &_red {
      color: #F45384;
    }
  }

  &__card-title {
    font-size: 18px;
    line-height: 26px;
    color: #B0B7D3;

    &_black {
      color: #30303A;

      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &_red {
      color: #F45384;
    }
  }

  &__card-sum {
    width: auto;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #30303A;

    .status-history {
      width: 200px;
    }

    &_red {
      color: #F45384;
    }


    &_grey {
      color: #B0B7D3;
    }

    &_desktop {
      display: table-cell;
    }

    &_mobile {
      display: none;
    }
  }

  &__status-title {
    &_mobile {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .pull-basket {
    &__order {
      padding: 12px;
    }

    &__cards-title {
      &:nth-child(4) {
        width: 190px;
      }
    }
  }
}

@media (max-width: 740px) {
  .load-button {
    height: 30px;
    border-radius: 8px;
    padding: 5px;

    .button {
      &__text {
        font-size: 10px;
      }
    }
  }

  .pull-basket {
    &__card-id, &__card-title, &__card-sum {
      font-size: 16px;
    }
  }
}

@media (max-width: 660px) {
  .pull-basket {
    &__cards-title {
      &:nth-child(4) {
        width: 78px;
      }
    }

    &__card-id {
      padding-left: 0;
    }

    &__title {
      font-size: 18px;
    }

    &__id-and-date {
      font-size: 20px;
    }

    &__order-sum {
      font-size: 16px;
    }

    &__status {
      text-align: center;
    }
  }
}

@media (max-width: 540px) {
  .pull-basket {
    &__id-and-date {
      font-size: 18px;
    }

    .status-history {
      width: 120px;
    }

    &__button-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__card-id, &__card-title, &__card-sum {
      font-size: 12px;
    }

    &__status-notification {
      font-size: 12px;
      padding: 5px;
      border-radius: 8px;
      align-items: center;
      gap: 8px;

      img {
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 420px) {
  .pull-basket {
    &__status {
      display: table-cell;
      height: auto;
      width: 0;
    }

    &_mobile-row {
      display: contents;
    }

    &__card {
      border-bottom: none;
    }

    &__id-and-date {
      font-size: 16px;
      line-height: 20px;
    }

    &__table-head {
      &_desktop {
        display: none;
      }
    }

    &_mobile {
      display: block;
    }

    &__card-title-container {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }

    &__order-sum {
      font-size: 14px;
    }

    &__card-id, &__card-title, &__card-sum {
      font-size: 11px;
    }
    
    &__position-is-changed {
      display: inline-block;
    }

    &__card-title {
      &_black {
        max-width: 150px;

        line-height: 16px;
      }
    }

    &__card-title {
      &_black {
        max-width: 150px;

        line-height: 16px;
      }
    }

    &__card-id {
      line-height: 12px;
      padding-top: 8px;

      &_black {
        color: #000;
      }
    }

    &__status-title {

      &_mobile {
        width: 100%;

        display: inline-block;
        
        font-size: 8px;
        color: #6F6F76;
        text-align: left;
      }
    }

    &__card-sum {
      width: fit-content;
      text-align: right;
      margin-top: 5px;

      &_desktop {
        display: none;
      }

      &_mobile {
        margin-top: 0px;
        display: block;
      }
    }

    &__card-sum-title {
      color: #6F6F76;
      font-family: 'Circe';
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__status {
      align-items: flex-start;
      text-align: left;
    }

    &__commission {
      width: 60px;
    }
  }
}