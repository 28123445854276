.profile-info {
  .second-input {
    max-width: 320px;
    width: 100%;
  }

  &__email {
    display: flex;
    align-items: center;
  }

  .second-input {
    &__input {
      width: 320px;
    }
  }

  &__info-container {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  &__log-out {
    display: inline-block;

    color: #4661C4;
    cursor: pointer;
    margin-top: 20px;

    border-bottom: 1px solid;
  }

  &__button-container {
    transition: opacity 0.3s linear;

    opacity: 0;

    &_active {
      opacity: 1;
    }
  }
}

@media (max-width: 1000px) {
  .profile-info {
    &__info-container {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: 600px) {
  .profile-info {
    &__email {
      align-items: flex-start;
      flex-direction: column;

      margin-bottom: 16px;
    }
  }
}
