.categories {
  min-width: 305px;
  width: 100%;

  &__container {
    position: relative;
  }

  &__title {
    font-size: 14px;
    line-height: 17px;
    color: rgba(48, 48, 58, 0.5);
    padding-left: 10px;
    margin: 0 0 8px;
  }

  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fff;
    border: 1px solid #B0B7D3;
    border-radius: 8px;

    transition: fall 0.3s linear;

    padding: 10px 16px;

    &_active {
      border: 1px solid #4661C4;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__select-categories {
    font-size: 16px;
    line-height: 150%;
    color: rgba(111, 111, 118, .5);

    transition: color 0.3s linear;

    &_active {
      color: #4661C4;
    }
  }

  &__arrow {
    transition: transform 0.3s linear;

    &_rotate {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    width: calc(100% - 10px);
    max-height: 0;

    display: flex;
    flex-direction: column;

    overflow-y: auto;

    background-color: #fff;
    border: 1px solid #4661C4;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    transition: all 0.3s linear;

    position: absolute;
    top: 46px;
    opacity: 0;
    z-index: 0;

    padding: 10px 4px;

    &_show {
      opacity: 1;
      z-index: 4;
      max-height: 250px;
    }
  }

  &__name-categories {
    display: block;
    font-size: 16px;
    line-height: 150%;
    color: rgba(48, 48, 58, 0.5);

    background-color: transparent;
    border-radius: 8px;

    cursor: pointer;

    transition: color 0.3s linear, background-color 0.3s linear;

    padding: 3px 12px;
    margin-bottom: 4px;

    &_active {
      color: #fff;
      background-color: #4661C4;
    }

    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: #4661C4;
      }
    }

    &_bold {
      cursor: default;
      font-weight: bold;

      &:hover {
        color: rgba(48, 48, 58, 0.5);
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 1100px) {
  .categories {
    min-width: 100%;
    width: 100%;

    margin-bottom: 12px;
  }
}

@media (max-width: 740px) {
  .categories {
    min-width: 270px;
  }
}
