.title {
  font-weight: 400;
  font-size: 50px;
  line-height: 150%;
  color: #1D1D2D;

  margin: 0 0 8px;
}

@media (max-width: 900px) {
  .title {
    font-size: 40px;
  }
}

@media (max-width: 660px) {
  .title {
    font-size: 33px;
  }
}

@media (max-width: 550px) {
  .title {
    font-size: 24px;
  }
}

@media (max-width: 420px) {
  .title {
    font-size: 24px;
  }
}

