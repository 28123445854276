@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
@font-face {
  font-family: 'Jost';
  src: url('../../../../../../../../assets/fonts/Jost.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Circe';
  src: url('../../../../../../../../assets/fonts/Circe-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.money-transfer-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 32px;

    .checkbox {
      margin-right: 8px;
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid #fff;
    }
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }

  &__tab-list {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  }

  &__sum {
    .second-input {
      &__input {
        width: 218px!important;
      }
    }
  }

  &__sum-color {
    .second-input {
      &__input {
        width: 218px!important;
        font-family: 'Jost';
        background-color: #fff!important;
        color: #151519!important;
        padding: 8px 16px;

        &::-webkit-input-placeholder {
          color: rgba(111, 111, 118, 0.5) !important;
        }
      }
    }
  }

  &__ndfl-markup-color {
    .second-input {
      &__input {
        font-family: 'Jost';
        background-color: #C7CFED!important;
        color: rgba(111, 111, 118, 0.5) !important;
        padding: 8px 16px;
      }
    }
  }

  &__markup {
    .second-input {
      width: 140px!important;
      &__input {
        width: 140px!important;
      }
    }
  }

  &__ndfl {
    .second-input {
      width: 140px!important;
      &__input {
        width: 140px!important;
      }
    }
  }

  &__markup-ndfl-container {
    display: flex;
    width: auto;
    flex-direction: row;
  }

  &__bridge {
    display: block;
  }

  &__button-transaction-ready {
    margin-top: 12px;
  }

  &__button-transaction {
    margin-top: 12px;
  }

  &__button-info {
    color: #FFFFFF;
    font-size: 11px;
    text-align: left;
  }

  &__ready-transaction-list {
    list-style-type: none;
    color: #FFFFFF;
    padding: 0;
    font-size: 17px;
    margin: 0;
  }

  &__cancel-transaction {
    color: rgba(255, 255, 255, 0.6);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    width: max-content;
    margin-top: 32px;
    cursor: pointer;
  }

  &__transaction-sum {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
  }

  &__ready-transaction {
    width: 100%;
    display: flex;
  }

  .button {
    background-color: #FFFFFF;
    text-align: center;
    border-radius: 8px;
    width: 100%;
    align-items: center;

    &__container {
      justify-content: center;
      align-items: center;
    }

    &__text {
      color: #4661C4;
    }
  }

  &__transaction-info {
    font-weight: 700;
  }

  .second-input {
    &__error {
      color: #FFDCE7;
      width: max-content;
    }

    &__input {
      font-size: 24px;
      font-family: 'Circe';
      padding: 8px 16px;
      width: 256px;
    }

    &__label {
      color: #FFFFFF;
      margin-bottom: 5px;
    }
  }

  &__sum-component {
    margin-top: 16px;
    display: flex;
    gap: 14px;
    flex-direction: row;
    justify-content: space-between;
  }

  &__tab-item {
    color: #FFFFFF;
    opacity: 0.6;
    cursor: pointer;
    font-size: 15px;
    text-transform: uppercase;

    &_active {
      opacity: 1;
      border-bottom: 1px solid #FFFFFF;
    }
  }

  &__phone-and-card {
    .second-input {
      width: 321px;

      &__input {
        font-size: 24px;
        font-family: 'Jost';
        padding: 8px 16px;
        width: 321px;
      }
    }
  }

  &__input-title {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 21px;
  }

  &__tab-content {
    margin-top: 32px;
  }

  &__selector-display {
    border: 1px solid #B0B7D3;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input {
      width: 100%!important;
      border: none;
      height: 35px;
      font-size: 16px;
      margin-left: 8px;

      &:focus-visible {
        border: none;
        outline: none;
      }
    }

    &_active {
      border-radius: 8px 8px 0 0;
      border-bottom: 0;
    }

    &_white {
      color: #6F6F76;
    }
  }

  &__selector-list {
    background-color: #FFFFFF;
    border: 1px solid #B0B7D3;
    border-top: none;
    border-radius: 8px;
    width: calc(100% - 2px);
    max-height: 0;

    overflow: auto;
    transition: all 0.3s linear;

    position: absolute;
    top: 67px;
    opacity: 0;
    z-index: -1;

    &::-webkit-scrollbar-thumb {
      margin-right: -100px;
      right: 100%;
      border-radius: 5px;
      background-color: #4661C4;
    }

    &_open {
      opacity: 1;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      z-index: 3;
      max-height: 250px;
    }
  }

  &__selector-input {
    position: relative;
    margin-top: 16px;
  }

  &__selector-item {
    border-radius: 8px;
    padding: 8px 12px;
    background-color: #FFFFFF;

    transition: all .3s linear;

    &:hover {
      background-color: #4661C4;
      color: #FFFFFF;
    }
  }

  .input {
    background-color: #FFFFFF !important;
    padding: 4px;
    width: 248px;

    &__input {
      font-weight: 400;
    }
  }
}

@media (max-width: 740px) {
  .money-transfer-tabs {

    &__sum {
      .second-input {
        &__input {
          width: 140px!important;
        }
      }
    }

    &__markup {
      .second-input {
        width: 140px!important;
        &__input {
          width: 140px!important;
        }
      }
    }

    &__ndfl {
      .second-input {
        width: 140px!important;

        &__input {
          width: 140px!important;
        }
      }
    }

    &__tab-item {
      font-size: 13px;
    }

    &__tab-content {
      margin-top: 24px;
    }


    &__checkbox {
      margin-top: 24px;
    }

    &__sum-component {
      .second-input {
        width: 135px;

        &__input {
          font-size: 20px;
          width: 135px;

          &::-webkit-input-placeholder {
            font-size: 20px;
          }
        }
      }
    }

    &__data-container {
      .second-input {
        width: 100%;

        &__input {
          width: 100%;

        }
      }
    }

    &__ready-transaction-list {
      font-size: 12px;
    }

    &__bridge {
      display: none;
    }

    &__button-info {
      font-size: 10px;
      margin-top: 0;
    }

    &__transaction-info {
      font-size: 12px;
    }

    &__transaction-sum {
      font-size: 16px;
    }

    &__cancel-transaction  {
      font-size: 14px;
    }

    .input {
      width: calc(100% - 8px);

      &__input {
        font-size: 20px;

        &::-webkit-input-placeholder {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .money-transfer-tabs {
    &__sum {
      .second-input {
        &__input {
          width: 120px!important;
        }
      }
    }

    &__markup {
      .second-input {
        width: 78px!important;

        &__input {
          width: 78px!important;
        }
      }
    }

    &__ndfl {
      .second-input {
        width: 78px!important;

        &__input {
          width: 78px!important;
        }
      }
    }
  }
}
