.activation-history-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__info {
    min-width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }

  &__container {
    width: 100%;

    background: #fff;
    border-bottom: 1.5px solid #f6f6f9;

    &_desktop {
      height: 52px;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_mobile {
      display: none;

      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }

  &__nominal {
    width: 20%;
    font-weight: 700;

    display: flex;
  }

  &__date {
    width: 40%;

    display: flex;
    justify-content: center;

    &_mobile {
      margin-bottom: 4px;
    }
  }

   &__expired  {
    width: 40%;

    display: flex;
    justify-content: flex-end;
  }

  &__number {
    justify-content: flex-start;
  }

  &__text {
    font-size: 18px;
    color: #30303A;
  }

  &__content {
    display: flex;
    justify-content: space-between;

    margin-bottom: 16px;
  }


  &__title {
    font-size: 16px;

    margin: 0 0 8px;
  }
}

@media (max-width: 1100px) {
  .activation-history-item {
    &__info {
      min-width: 70%;
    }
  }
}

@media (max-width: 950px) {
  .activation-history-item {
    &__info {
      min-width: 75%;
    }
  }
}

@media (max-width: 750px) {
  .activation-history-item {
    &__text {
      font-size: 12px;
    }
  }
}

@media (max-width: 575px) {
  .activation-history-item {
    &__container {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }
  }
}