.checkbox {
  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border: 2px solid #B0B7D3;
  border-radius: 2px;

  cursor: pointer;

  transition: all 0.3s linear;

  &_active {
    background-color: #4661C4;
    border: 2px solid #4661C4;
  }

  &__icon {
    transition: all 0.3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    &_hide {
      opacity: 0;
    }
  }
}
