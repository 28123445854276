.reject-order {
  &__container {
    width: 300px;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #FF4B40;

    margin: 0 0 16px;
  }
}

@media (max-width: 768px) {
  .reject-order {
    &__popup-content {
      width: 100%;
      height: 100%;

      align-items: center;
    }
  }
}

@media (max-width: 350px) {
  .reject-order {
    &__title {
      font-size: 22px;
    }
  }
}