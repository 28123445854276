.money-transfer {
  .title {
    font-size: 40px;
  }

  &__balance {
    font-size: 22px;
    margin-bottom: 16px;
    color: #6F6F76;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 80px;
  }


  .second-input {
    &__input {
      &::-webkit-input-placeholder {
        color: #afafaf;
      }
    }
  }

  .input {
    &__input {
      &::-webkit-input-placeholder {
        color: #afafaf;
      }
    }
  }

  &__transfer-block {
    min-width: calc(622px - 96px);
    max-width: calc(622px - 96px);
    background-color: #4661C4;
    height: max-content;
    border-radius: 16px;
    padding: 48px;

  }
  &__sum {
    color: #F45384;
  }

  &__info {
    font-size: 18px;
    line-height: 21.6px;
    color: #5F5F6B;
  }
}

@media (max-width: 1440px) {
  .money-transfer {
    &__container {
      flex-direction: column;
      gap: 32px;
    }
  }
}

@media (max-width: 1024px) {
  .money-transfer {
    &__transfer-block {
      padding: 24px;
    }

    &__sum {
      font-size: 22px;
    }

    &__info {
      font-size: 14px;
    }

    .title {
      font-size: 32px;
    }
  }
}

@media (max-width: 740px) {
  .money-transfer {
    &__transfer-block {
      min-width: auto;
    }

    &__sum {
      font-size: 22px;
    }

    .title {
      font-size: 24px;
      letter-spacing: -0.2px;
      line-height: 26px;
    }
  }
}
