@font-face {
  font-family: 'Circe';
  src: url('../../../../../assets/fonts/Circe-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.money-transfer-success {
  &__container {
    width: 300px;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    color: #1D1D2D;
    text-align: center;
    font-family: 'Circe';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  &__subtitle {
    color: #30303A;
    text-align: center;
    font-family: 'Circe';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .money-transfer-success {
    &__popup-content {
      width: 100%;
      height: 100%;

      align-items: center;
    }
  }
}

@media (max-width: 350px) {
  .money-transfer-success {
    &__title {
      font-size: 22px;
    }
  }
}
