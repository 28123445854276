.nominal-table {
  width: 100%;

  border-collapse: collapse;

  &__content {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #4661C4;
  }

  &__body-container {
    width: 217%;
    max-height: 168px;
    overflow-y: scroll;
  }

  &__body-table {
    border-collapse: collapse;
    width: 100%;
  }

  &__container {
    //max-height: 200px;

    overflow: hidden;
    margin-right: -20px;
    padding-right: 16px;


    &::-webkit-scrollbar {
      margin-right: -100px;
      width: 4px;
      border-radius: 4px;
      background-color: #E8EBF6;
    }

    &::-webkit-scrollbar-thumb {
      margin-right: -100px;
      right: 100%;
      border-radius: 4px;
      background-color: #4661C4;
    }
  }

  &__error {
    text-align: center;
    font-size: 12px;
    min-height: 10px;
    margin-top: 10px;
    color: #f00;
  }

  &__loading {
    min-height: auto;
    height: 100%;

    background-color: #fff;

    padding-top: 40px;
  }

  &__empty-table {
    width: 0;
    font-size: 14px;
    color: #4661C4;
    cursor: pointer;
    transition: all 0.3s linear;

    opacity: 0;
    height: 0;
    z-index: -1;

    padding-left: 16px;
    margin: 0;

    &_show {
      height: auto;
      width: auto;

      opacity: 1;
      z-index: 1;

      margin: 16px 0;
    }
  }

  &__label-container {
    border-bottom: 1px solid #B0B7D3;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 2px;
  }

  &__label {
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #6F6F76;
    min-width: 63px;

    &:first-child {
      text-align: left;
      padding-left: 16px;
    }

    &_hide-desktop {
      display: none;
    }
  }

  &__info-container {
    background-color: #fff;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f8;
    transition: all 0.3s linear;
    opacity: 1;

    &_selected {
      background-color: #f2f3f8;
    }

    @media (hover: hover) {
      &:hover {
        background-color: #e9ebf6;
  
        .nominal-table__cross {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    &_red {
      background-color: rgba(233, 172, 171, 0.3);
    }

    &_yellow {
      height: 30px;

      position: relative;

      background-color: rgba(246, 213, 178, 0.3);

      &:hover {
        background-color: rgba(246, 213, 178, 0.3);
      }
    }

    &_hide {
      height: 0;
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

  &__cell {
    display: none;
  }

  &__nominal {
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #30303A;
    width: 63px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding-left: 16px;

    &_color-grey {
      color: #a19f9f;
    }
  }

  &__markup {
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #30303A;
    width: 63px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_color-grey {
      color: #a19f9f;
    }
  }

  &__commission{
    padding-left: 35px;
  }

  &__line-between-list {
    position: absolute;
    top: 7px;

    font-size: 10px;
    line-height: 150%;
    color: #6F6F76;
  }

  &__line-between-list-container {
    position: relative;
    height: inherit;
  }

  &__qty {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #30303A;
    width: 63px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_zero {
      display: inline-block;

      font-size: 9px;
      line-height: 13px;
      color: #F45384;
    }

    &_desktop {
      display: contents;
    }

    &_font-small {
      font-size: 11px;
      color: #F45384;
    }
  }

  &__nominal-with-qty {
    display: none;

    padding-right: 10px;
  }

  &__nominal-with-qty-cell {
    display: flex;
    align-items: center;
  }

  &__counter {
    display: flex;
    justify-content: center;
    width: 63px;
  }

  &__cross {
    text-align: center;
    width: 45px;
    transition: opacity 0.3s linear;
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;

    padding-top: 4px;

    &_mobile {
      display: contents;
    }

    &_text-replace-icon {
      width: 40px;

      font-weight: 400;
      font-size: 10px;
      line-height: 150%;
      text-align: left;
      text-decoration-line: underline;
      color: #6F6F76;

      transition: none;
      opacity: 1;

      padding-bottom: 8px;
    }
  }
}

@media (max-width: 460px) {
  .nominal-table {
    &__cross {
      &_mobile {
        display: contents;
      }
    }

    &__qty {
      &_desktop {
        display: none;
      }
    }

    &__label {

      &:nth-child(3) {
        text-align: left;

        padding-left: 16px;
      }
    }

    &__container {
      max-height: 100%;
      overflow-y: hidden;
    }
  }
}

@media (max-width: 425px) {
  .nominal-table {
    &__content {
      height: auto;
    }
  }
}
