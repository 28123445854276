.list-cart {
  min-height: 73px;

  position: relative;

  &__checkbox {
    margin-right: 8px;
    margin-bottom: 3px;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__item-container {
    background-color: #fff;

    transition: all 0.5s linear;
    border-radius: 8px;

    .list-cart__icon {
      opacity: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 30px;
    }

    &:hover {
      background-color: #E8EBF6;

      .list-cart__item {
        border-bottom: 1px solid #fff;
      }
    }
  }

  &__bottom-holder {
    height: 20px;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
  }

  &__error {
    text-align: left;
    font-size: 12px;
    color: #f00;
    min-height: 15px;

    margin-top: 10px;
  }

  &__table-head {
    display: flex;
    justify-content: space-between;

    background-color: inherit;
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid #E8EBF6;

    padding: 20px 20px 0;
  }

  &__item-head {
    display: none;
  }

  &__loading {
    min-height: 100%;
    align-items: center;

    background-color: #fff;
    border-radius: 16px;

    transition: all 0.3s ease-in;

    padding-top: 0;
  }

  &__container {
    width: 100%;

    position: relative;

    border-collapse: collapse;
    border-radius: 8px;
  }

  &__label {
    font-size: 12px;
    line-height: 18px;
    color: #6F6F76;
    width: 20%;
  }

  &__animation {
    &-exit-active {
      animation: fade-out-item 0.5s forwards;
    }
  }

  &__item {
    height: 52px;
    padding: 0 20px;
    background: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E8EBF6;

    cursor: pointer;

    &:hover {
      background-color: #E8EBF6;

      .list-cart__icon {
        opacity: 1;
      }
    }
  }

  &__name-container {
    display: inline-flex;
    align-items: flex-end;

    position: relative;

    margin-left: 8px;

    &_desktop {
      width: 30%;
    }
  }

  &__name {
    font-size: 16px;
    white-space: nowrap;
    display: flex;
    color: #B0B7D3;

    &_black {
      color: #30303A;
    }

    &_hidden {
      overflow: hidden;
      white-space: nowrap;
      background-color: inherit;
      width: 16vw;
      transition: all 100ms ease-in-out;
      text-overflow: ellipsis;

      &:hover {
        width: max-content;
        background-color: #E8EBF6;
      }
    }
  }

  &__time {
    display: block;

    font-size: 11px;
    line-height: 16px;
    color: #F45384;

    position: absolute;
    bottom: -13px;
  }

  &__is-reserved {
    font-size: 10px;
    color: #F45384;
    position: absolute;
    width: max-content;
    top: -12px;
    z-index: 1;
  }

  &__price-container {
    text-align: center;
  }

  &__price {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #6F6F76;

    margin: 0 32px;
  }

  &__commission {
    font-size: 16px;
  }
}

.main-info {
  width: 60%;
  display: flex;
  text-align: center;
  justify-content: space-between;

  &__count {
    text-align: center;
  }

  &__price {
    font-weight: 700;
    font-size: 18px;
    color: #6F6F76;
  }

  &__commission {
    font-size: 15px;
    color: #4661C4;
  }

  &__title {
    display: none;
  }

  &__counter {
    justify-content: center;
  }

  &__total-container {
    width: 100px;
    text-align: center;
  }

  &__total {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #30303A;
  }

  &__icon-container {
    text-align: center;
    width: 60px;
  }

  &__icon {
    transition: opacity 0.5s linear;

    margin: 8px 8px 0;
  }

  &__nominal-container {
    width: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__sum {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container {
    width: 60%;
    display: flex;
    text-align: center;
    justify-content: space-between;
  }
}

.cart-head {
  font-size: 12px;
  background-color: #fff;
  line-height: 18px;
  color: #6F6F76;

  &__table-head {
    background-color: #fff;

    font-size: 18px;
    font-weight: 700;
    color: #1D1D2D;

    padding: 10px 20px;
  }

  &__commission {
    width: 75px;
  }

  &__name {
    width: 40%;
  }

  &__count {
    text-align: center;
    width: 100px;
  }

  &__nominal {
    text-align: center;
    width: 75px;
  }

  &__sum {
    text-align: center;
    width: 100px;
  }

  &__container {
    width: 60%;
    display: flex;
    text-align: center;
    justify-content: space-between;
  }

  &__cross {
    width: 60px;
  }
}

@keyframes fade-out-item {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

@media (max-width: 1130px) {
  .list-cart {
    &__total-container {
      width: 100px;
    }
  }
}

@media (max-width: 600px) {
  .main-info {
    &__total-container {
      width: 80px;
    }

    &__total, &__price, &__name {
      font-size: 14px;
    }
  }
}

@media (max-width: 600px) {
  .main-info {
    &__total-container {
      width: 80px;
    }

    &__icon-container {
      width: 25px;
    }

    &__total, &__price, &__name {
      font-size: 14px;
    }

    &__container {
      width: 65%;
    }
  }

  .cart-head {
    &__cross {
      width: 25px;
    }

    &__container {
      width: 65%;
    }
  }
}

@media (max-width: 555px) {
  .main-info {
    &__nominal,
    &__sum,
    &__cross,
    &__nominal-container,
    &__total-container {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .counter {
      padding: 0 4px;
    }

    &__nominal-container {
      display: block;
    }

    &__title {
      display: block;
      font-size: 10px;
      color: #6F6F76;
      margin-bottom: 5px;

      &_text-center {
        text-align: center;
      }

      &_text-right {
        text-align: right;
      }
    }

    &__container {
      width: 100%;
    }

    &__icon-container {
      display: none;

      &_mobile {
        display: block;
      }
    }
  }

  .list-cart {
    margin-right: 0;

    &__bottom-holder {
      display: none;
    }

    &__is-reserved {
      top: -13px;
    }

    &__price-container {
      text-align: left;
    }

    &__price{
      margin: 0;
    }

    &__item-head {
      display: flex;
      height: 40px;
      border-radius: 8px 8px 0 0;
      margin-top: 1px;
      padding: 14px 14px 0;
      background-color: inherit;
      justify-content: space-between;
      align-items: center;
    }

    &__name {
      &_hidden {
        width: 55vw;
        top: 0;

        &:hover {
          white-space: break-spaces;
          width: 70vw;
        }
      }
    }

    &__name-container {
      margin: 0;
      align-items: flex-end;

      &_desktop {
        align-items: flex-end;
        display: none;
      }
    }

    &__total-container {
      width: 65px;
      text-align: right;
    }

    &__item-container:hover {
      .list-cart__item {
        border-bottom: 4px solid #f6f6f9;
      }
    }

    &__item {
      display: flex;
      flex-wrap: wrap;
      border-radius: 0 0 8px 8px;
      border-bottom: 4px solid #f6f6f9;
      width: auto;
      padding: 8px 14px 0;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__table-head {
      display: none;
    }
  }

  .cart-head {
    &__table-body {
      display: flex;
      flex-direction: column;
    }

    &__table-head {
      background-color: transparent;

      font-size: 16px;

      padding-left: 0;
      padding-bottom: 4px;
    }
  }

}
