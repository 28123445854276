.cards-over {
  max-width: 350px;
  width: 100%;

  text-align: center;

  overflow-x: hidden;

  &__description {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #30303A;

    margin: 0 0 16px;

    &_uppercase {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__amulet {
    margin-bottom: 16px;
  }

  &__tip-container {
    display: inline;

    cursor: pointer;

    position: relative;
  }

  &__question {
    margin-bottom: 2px;
    margin-left: 5px;
  }

  &__tip-position {
    bottom: 0;
  }

  &__button-back, &__button-to-order {
    height: 70px;

    padding-left: 40px;
    padding-right: 40px;

    .button__text {
      font-weight: 400;
    }
  }

  &__button-back {
    .button__text {
      color: #30303A;
    }
  }
}

@media (max-width: 768px) {
  .cards-over__popup-content {
    width: 100%;
    height: 100%;

    align-items: center;
  }
}

@media (max-width: 360px) {
  .cards-over {
    &__button-back, &__button-to-order {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}