.status-history {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 176px;
  
  &__content {
    display: flex;
    align-items: center;
  }

  &__button {
    width: 200px;
    height: 30px;
    border-radius: 8px;
    background-color: #40C179;

    .button {
      &__text {
        color: #fff;
      }

      &__icon {
        height: 18px;
      }
    }
  }

  &__text {
    font-size: 16px;
    margin-left: 5px;
  }

  &__icon {
    margin-bottom: 3px;
  }

  &__new {
    color: #4661C4;
  }

  &__processing {
    color: #4661C4;
  }

  &__paid {
    color: #1BB961;
  }

  &__cancelled {
    color: #F7526D;
  }

  &__changed {
    color: #F7526D;
  }

  &__waiting-to-sent {
    color: #4661C4;
  }
}

@media (max-width: 560px) {
  .status-history {
    display: inline-block;;
    width: unset;

    &__text {
      font-size: 8px;
      margin-bottom: 2px;
    }

    &__button {
      width: max-content;
      height: 10px;

      .button {
        &__icon {
          width: 14px;
        }

        &__text {
          font-size: 8px;
        }
      }
    }

    &__icon {
      width: 12px;
    }
  }
}
