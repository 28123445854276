.error-place-order {
  &__container {
    max-width: 400px;
    width: 100%;

    text-align: center;
  }

  &__icon {
    margin-bottom: 12px;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #1D1D2D;

    margin: 0 0 16px;

    &_color-pink {
      color: #F45384;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: #2D2B37;

    margin: 0;
  }

  &__link {
    font-weight: 600;
    color: #4661C4;

    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .error-place-order {
    &__popup-content {
      width: 100%;
      height: 100%;

      align-items: center;
    }
  }
}
