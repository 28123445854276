.pwa {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__content {
    max-width: 312px;
    width: 100%;
  }

  &__popup-content {
    width: 100%;

    align-self: end;

    border-radius: 0;
    text-align: center;

    padding: 63px 32px 38px;
  }

  &__popup-content_installed {
    padding: 52px 19px 50px 17px;

    .popup__close {
      width: 32px;
      max-width: 32px;
      height: 32px;

      top: 12px;
      right: 12px;
    }
  }

  &__notification-icon {
    width: 76px;
    height: 76px;
    max-width: 76px;

    position: absolute;
    top: -37px;
    left: 50%;
    right: 50%;

    border-radius: 18px;

    transform: translateX(-50%);
    box-shadow: 0 4px 16px #3E445333;

    .svg-icon {
      border-radius: 18px;
    }

    &_installed {
      width: 76px;
      height: 76px;
      max-width: 76px;

      margin: 0 24px 0 0;

      .svg-icon {
        border-radius: 18px;
      }
    }
  }

  &__wrapper_installed {
    display: flex;

    .popup-timer {
      font-size: 12px;
      line-height: 12px;
      text-align: left;
    }
  }

  &__title {
    max-width: 307px;
    
    color: #30303A;
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;

    margin: 0 0 4px;
  }

  &__title_installed {
    text-align: left;

    margin: 0 0 12px;
  }

  &__text {
    color: #6F6F76;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;

    margin: 0 0 16px;
  }

  &__button {
    max-width: 312px;
    width: 100%;
    height: 43px;

    font-family: 'Jost', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;

    border-radius: 8px;
    box-shadow: 0 0 2px #2B324D29;

    padding: 12px 5px;
  }
}

@media (max-width: 768px) {
  .pwa {
    
  }
}
