.money-transfer-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 502px;
  text-align: center;

  &__image {
    width: 48px;
    height: 48px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
  }

  &__list {
    font-size: 18px;
    list-style-type: none;
    padding: 0;
    line-height: 21.6px;
    color: #5F5F6B;
    margin: 8px 0 24px;
  }

  &__subtitle {
    font-size: 18px;
    color: #5F5F6B;
  }
}

@media (max-width: 897px) {
  .money-transfer-alert {
    &__subtitle {
      font-size: 16px;
    }

    &__list {
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .money-transfer-alert {
    width: unset;
    
    &-popup__content {
      width: 100%;
      height: 100%;

      align-items: center;
    }
  }
}
