.radio-buttons {
  margin-bottom: 40px;
}

.radio-buttons__label {
  display: flex;
  align-items: center;

  cursor: pointer;

  margin: 0 0 8px;
}

.radio-buttons__label .radio-buttons__input[type="radio"] {
  display: none;
}

.radio-buttons__value {
  font-size: 16px;

  color: #676767;
}

.radio-buttons__checkmark {
  width: 20px;
  height: 20px;

  background-color: #fff;
  border: 2px solid #676767;
  border-radius: 50%;

  position: relative;

  transition: background-color 0.3s ease;

  margin-right: 8px;
}

.radio-buttons__label .radio-buttons__input[type="radio"]:checked + .radio-buttons__checkmark {
  background-color: #fff;
  border: 2px solid #4661C4;
}

.radio-buttons__checkmark::after {
  width: 12px;
  height: 12px;

  background-color: #4661C4;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s ease;

  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
}

.radio-buttons__label .radio-buttons__input[type="radio"]:checked + .radio-buttons__checkmark::after {
  transform: scale(1);
}

@media (max-width: 450px) {
  .radio-buttons__value {
    font-size: 15px;
  }
}

