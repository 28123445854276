.completed-transfer {
  &__container {
    width: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;

    margin: 0 0 16px;
  }
}

@media (max-width: 768px) {
  .completed-transfer {
    &__popup-content {
      width: 100%;
      height: 100%;

      align-items: center;
    }
  }
}