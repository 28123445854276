.auth {
  &__popup-content {
    overflow-y: hidden;
  }

  &_popup-with-other-project {
    background-color: #fff;

    .popup__content {
      border: 1px solid #B0B7D3;
    }
  }

  &__container {
    max-width: 347px;
    width: 100%;

    margin: 0 auto;
  }

  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    color: #1D1D2D;

    margin: 0 0 30px;
  }

  &__description {
    font-size: 18px;
    color: #5F5F6B;
    margin-bottom: 30px;
  }

  &__rules-and-pers {
    font-size: 12px;
    line-height: 19px;
    color: rgba(48, 48, 58, 0.7);

    margin: 0;
    cursor: pointer;

    &_color-blue {
      color: #4661C4;
      cursor: pointer;
    }
  }
}

@media (max-width: 897px)  {
  .auth {
    &__popup-content {
      width: 100%;
      height: 100%;

      border-radius: 0;

      overflow-y: auto;
      padding: 0;
    }

    &__container {
      height: 100%;
    }
  }
}

@media (max-width: 768px) {
  .auth {
    &__popup-content {
      justify-content: flex-start;
    }

    &__container {
      overflow-y: auto;
    }
  }
}

@media (max-width: 480px) {
  .auth {
    &__container {
      max-width: 313px;
    }
  }
}
