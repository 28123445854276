.ndfl_info_popup {
  &__title {
    font-family: Jost;
    font-size: 18px;
    font-weight: 700;
    line-height: 23.4px;
    text-align: center;
    margin-bottom: 10px;
    color: #1D1D2D;
  }

  &__container {
    display: flex;
    max-width: 460px;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-family: Jost;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    color: #5F5F6B;
    margin-bottom: 10px;
  }

  &__link {
    font-family: Jost;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    text-decoration: underline;
    color: #4661C4;
  }
}

@media (max-width: 768px) {
  .ndfl-info__popup-content {
    width: 100%;
    height: 100%;

    align-items: center;
  }
}
