.private-delivery-radio-buttons {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 32px;
}

.private-delivery-radio-buttons__button {
  border-radius: 5px;
  background-color: white;
  border: none;

  font-size: 16px;
  color: #676767;
  cursor: pointer;
  transition: all 0.3s ease;

  padding: 10px 20px;
  margin-right: 12px;
  margin-bottom: 12px;

  &:last-child {
    margin-right: 0;
  }
}

.private-delivery-radio-buttons__button:hover {
  background-color: rgba(#4661C4, 0.8);

  color: #fff;
}

.private-delivery-radio-buttons__button--selected {
  background-color: #4661C4;

  color: #fff;
}