.cart {
  &__container {
    position: relative;
  }

  &__markup-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__button {
    width: 262px;
    background-color: #4661C4;

    .button {
      &__text {
        color: #fff;
      }
    }
  }

  &__purses {
    margin-bottom: 24px;
  }

  &__subtitle {
    font-size: 22px;
    line-height: 32px;
    color: #30303A;

    margin: 0 0 8px;
  }

  &__user-balance {
    font-size: 25px;
    color: #F45384;
    white-space: nowrap;
  }

  &__warning {
    display: inline-flex;
    align-items: center;

    background-color: #fff;
    border: 1px solid #F45384;
    border-radius: 16px;

    padding: 16px 20px;
    margin: 0 0 32px;
  }

  &__warning-icon {
    margin-right: 16px;
  }

  &__warning-text {
    font-size: 14px;
    line-height: 121%;
    color: #6F6F76;
    margin: 0;
    font-weight: bold;
  }

  &__switch-container {
    position: relative;

    &-enter-active {
      animation: fade-in 0.6s linear;
    }

    &-exit-active {
      animation: fade-out 0.6s linear;
    }
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 12px;
  }

  &__checkbox {
    display: flex;
    align-items: center;

    cursor: pointer;

    margin-right: 24px;

    .checkbox {
      margin-right: 8px;
    }
  }

  &__checkbox-label {
    font-size: 16px;
    line-height: 26px;
    color: #30303A;
  }

  &__link {
    font-size: 16px;
    border-bottom: 1px solid;
    line-height: 1em;
    color: #F45384;

    cursor: pointer;

    transition: opacity 0.3s linear;

    z-index: -1;
    opacity: 0;

    &_active {
      z-index: 1;
      opacity: 1;
    }
  }

  &__content {
    display: flex;
    margin-bottom: 70px;
    align-items: flex-start;
  }

  &__table {
    width: 100%;
    position: relative;

    margin-right: 30px;
  }

  &__info-container {
    max-width: 267px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_mobile {
      display: none;
    }
  }

  &__total-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 130%;
    color: #1D1D2D;

    margin: 0 0 16px;

    &_mobile {
      display: none;
    }

    &_desktop {
      display: block;
    }
  }

  &__qty {
    display: block;
    font-size: 16px;
    line-height: 121%;
    color: #4661C4;

    margin-bottom: 16px;

    &_mobile {
      display: none;
    }

    &_desktop {
      display: flex;
    }
  }

  &__total-container {
    margin-bottom: 5px;
  }

  &__total-label {
    display: block;

    font-size: 16px;
    line-height: 121%;
    color: #6F6F76;
  }

  &__total {
    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    color: #30303A;
  }

  &__commission-container {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    margin-bottom: 10px;
    flex-direction: row;
  }

  &__commission-label {
    display: block;
    font-size: 16px;
    line-height: 121%;
    color: #6F6F76;
  }

  &__commission {
    font-weight: 700;
    font-size: 16px;
    line-height: 121%;
    color: #6F6F76;
  }

  &__error {
    position: absolute;
    bottom: 55px;

    font-size: 16px;
    line-height: 26px;
    color: #F45384;

    transition: opacity 0.3s linear;

    margin-bottom: 16px;

    opacity: 0;
    z-index: -1;

    &_show {
      opacity: 1;
      z-index: auto;
    }
  }

  &__container-button {
    height: 350px;

    margin-top: 32px;

    .button {
      width: 262px;
    }
  }

  &__arrow {
    width: 90%;

    display: none;

    transition: opacity 0.3s linear, visibility 0.3s linear;
    animation: move-left-right 1.5s linear infinite;

    text-align: right;

    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes move-left-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1080px) {
  .cart {
    &__content {
      flex-direction: column;
    }

    &__table {
      margin-right: 0;
    }

    .arrow-table {
      display: block;
    }
  }
}

@media (max-width: 830px) {
  .cart {
    &__subtitle {
      font-size: 20px;
    }

    &__user-balance {
      font-size: 23px;
    }
  }
}

@media (max-width: 670px) {
  .cart {
    &__subtitle {
      font-size: 18px;
    }

    &__user-balance {
      font-size: 21px;
    }
  }
}

@media (max-width: 640px) {
  .cart {
    &__subtitle {
      font-size: 16px;
    }

    &__container {
      margin-bottom: 120px;
    }

    &__user-balance {
      font-size: 20px;
    }
  }
}

@media (max-width: 555px) {
  .cart {
    &__warning-text {
      font-size: 12px;
    }

    &__checkbox-container {
      justify-content: space-between;
    }

    &__button {
      width: 100%;

      .button {
        &__text {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    &__total-container {
      align-items: flex-end;
    }

    &__info-container {
      background: #fff;
      width: calc(100% - 32px);
      padding: 16px;
      max-width: 100%;
      border-radius: 8px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__table {
      overflow-y: hidden;
      overflow-x: scroll;
    }

    .arrow-table {
      &_active {
        opacity: 1 !important;
        visibility: initial !important;
      }
    }

    &__qty {
      text-align: right;
      font-size: 16px;
    }

    &__total {
      font-size: 34px;
    }

    &__container-button {
      display: flex;
      justify-content: center;

      .button{
        width: 100%;
      }
    }
  }
}

@media (max-width: 470px) {
  .cart {
    &__checkbox-label,
    &__link {
      font-size: 12px;
    }

    &__markup-container {
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &__qty {
      margin-bottom: 0;
      font-weight: 400;

      &_mobile {
        display: block;
      }

      &_desktop {
        display: none;
      }
    }

    &__total {
      &_mobile {
        font-size: 18px;
        font-weight: 800;
      }
    }

    &__button {
      width: auto;
      padding: 20px;

      &_desktop {
        display: none;
      }

      .button {
        &__text {
          font-size: 11px;
        }
      }
    }

    &__info-container {
     &_mobile{
       box-shadow: 0px -8px 20px 0px rgba(200, 200, 200, 0.3);;
       position: fixed;
       bottom: 52px;
       left: 0;
       display: flex;
       z-index: 1;
       background: #fff;
       width: calc(100% - 40px);
       padding: 5px 20px;
       justify-content: space-between;
     }
    }

    &__info {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    &__total-container {
      align-items: center;
    }

    &__title {
      font-size: 16px;

      &_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &_desktop {
        display: none;
      }
    }

    &__total-label {
      font-size: 16px;

      &_mobile {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 350px) {
  .cart {
    &__checkbox-label,
    &__link {
      font-size: 13px;
    }
  }
}
