.upward-button {
  position: fixed;
  right: 20px;
  bottom: 100px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);

  z-index: 2;

  background-color: #fff;
  border: 1px solid #f6f6f9;
  border-radius: 50%;

  display: none;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 70px;

  &__active {
    display: flex;
  }
}

@media (max-width: 630px) {
  .upward-button {
    width: 70px;
    height: 70px;

    bottom: 110px;
  }
}
