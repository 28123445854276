@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.help-banner {
  width: 330px;
  height: min-content;
  
  flex-direction: column;
  
  background-color: #FFFFFF;
  border-radius: 8px;
  
  padding: 24px 32px 24px 32px;
  margin: 0 0 16px;

  &__desktop {
    display: flex;
  }

  &__mobile {
    display: none;
  }

  &__image {
    width: 89px;
    margin-bottom: 20px;
  }

  &__title {
    font-family: Jost;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
    text-align: left;
    color: #1D1D2D;
  }
}

.help-item {
  overflow: hidden;
  transition: all 400ms linear;
  position: relative;

  &_first-item {
    //border-top: 1px solid #b0b7d3;
  }

  &_show {
    max-height: 400px;
    border-bottom: 1px solid #4661C4;

    .help-item {
      &__title-text {
        color: #4661C4;
      }
    }
  }

  &_hide {
    border-bottom: 1px solid #B0B7D3;
  }

  &__text {
    margin-bottom: 0;
    position: absolute;
    max-height: 0;
    transition: all 300ms linear;
    overflow: hidden;

    &_active {
      position: relative;
      margin-bottom: 20px;
      max-height: 300px;
    }

    ol, ul {
      padding: 0 0 0 20px;
    }
  }

  &__title-text {
    width: 90%;
    color: #1d1d2d;
    font-family: Jost;
    font-size: 18px;
    font-weight: 400;
    line-height: 26.01px;
    text-align: left;

  }

  &__title {
    color: #1D1D2D;
    font-family: Jost;
    //height: 50px;
    padding: 10px 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 26.01px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;

    svg {
      transition: all 400ms linear;
    }

    &_active {
      color: #4661C4;

      svg {
        transform: rotate(180deg);

        path {
          fill: #4661C4;
        }
      }
    }
  }
}

.help {
  margin-bottom: 400px;

  .inner-tabs {
    overflow: hidden;
    &__container {
      overflow: hidden;
      overflow-x: scroll;
      
      border: none;
      
      padding: 0 0 4px;

      &::-webkit-scrollbar {
        height: 1.5px;
        background-color: #B0B7D3;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #4661C4;
      }
    }
    &__item {
      text-wrap: nowrap;
      user-select: none;
    }
    &__content {
      margin-top: 30px;
    }

    &__item_active {
      z-index: 2;
    }
  }

  &__ndfl-page {
    margin-top: 35px;
  }

  &__questions {
    display: flex;
    flex-direction: column;
  }

  &__questions-group-title {
    font-family: Circe;
    color: #30303A;
    font-size: 24px;
    font-weight: 700;
    line-height: 35.38px;
    text-align: left;
    margin-bottom: 15px;
  }

  &__questions-group {
    margin-bottom: 30px;
    width: 840px;
  }

  &__global-container {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  &__container {
    margin-top: 20px;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 160%;
    color: #5F5F6B;

    margin: 0 0 16px;
  }

  &__chat-container {
    display: flex;
    align-items: center;

    text-decoration: none;

    margin-bottom: 12px;
  }

  &__chat-container {
    cursor: pointer;

    margin-right: 16px;

    &:hover {
      .help__text {
        color: #4661C4;
      }
    }
  }

  &__contact-megafon {
    max-width: 660px;
    font-size: 18px;
    line-height: 160%;
    color: #5F5F6B;

    transition: color 0.5s linear;
  }

  &__label {
    text-decoration-line: none;
    color: #5F5F6B;

    margin: 0 12px 0 0;
  }

  &__text {
    font-size: 16px;
    line-height: 160%;
    text-decoration-line: underline;
    white-space: nowrap;
    color: #5F5F6B;

    transition: color 0.5s linear;
  }

  &__img {
    width: 32px;
    height: 32px;

    margin-right: 8px;
  }
}

@media (max-width: 1440px) {
  .help {
    &__questions-group {
      width: 50vw;
    }
  }
}


@media (max-width: 1024px) {
  .help {
    &__questions-group {
      width: 40vw;
    }
  }
}

@media (max-width: 920px) {
  .help {
    &__questions-group {
      width: auto;
    }

    &__global-container {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 700px) {
  .help {
    margin-bottom: 200px;

    &__container {
      align-items: flex-start;
      flex-direction: column;
    }

    &__chat-container {
      &:first-child {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}

@media (max-width: 920px) {
  .help-banner {
    width: auto;
    max-width: 340px;
    padding: 24px 32px;
    margin-bottom: 15px;

    &__title {
      font-size: 14px;
    }

    .help {
      &__text {
        font-size: 14px;
      }

      &__container {
        margin-top: 10px;
      }

      &__chat-container {
        margin-bottom: 3px;
      }

      &__img {
        width: 24px;
      }
    }
    &__desktop {
      display: none;
    }

    &__mobile {
      display: flex;
    }
  }

  .help {
    &__text {
      font-size: 12px;
    }
  }

  .help-item {
    &_hide {
      //max-height: 64px;
    }

    &__title {
      font-size: 12px;
    }

    &__text {
      font-size: 12px;
    }
  }
}

@media (max-width: 500px) {
  .help {
    &__questions-group-title {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  .help-item {
    &_hide {
      //height: 40px;
    }

    &__title {
      //height: 40px;
    }

    &__title-text {
      font-size: 14px;
    }
  }
}

