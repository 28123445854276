.code-form {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1D1D2D;

    margin: 0 0 24px;
  }

  &__error {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #ff0000;
  }

  &__number {
    font-size: 18px;
    line-height: 24px;
    color: #1D1D2D;

    margin: 0 0 20px;

    &_non-wrap {
      white-space: nowrap;
    }
  }

  &__change-number {
    display: block;

    font-size: 14px;
    line-height: 24px;
    color: rgba(70, 97, 196, 0.7);

    text-decoration-line: underline;

    margin-bottom: 12px;
  }

  &__timer {
    font-size: 18px;
    line-height: 24px;

    margin: 0 0 93px;
  }
}
