.go-to-account {
  max-width: 400px;
  width: 100%;

  font-size: 22px;
  font-weight: 500;

  text-align: center;

  margin: 0 auto;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__error-title {
    max-width: 380px;

    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;

    text-align: center;

    margin: 0 0 24px;
  }

  &__link {
    font-size: 16px;
    color: #4661C4;
    cursor: pointer;
    text-decoration: underline;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #1D1D2D;

    margin: 0 0 32px;
  }
}

@media (max-width: 768px) {
  .go-to-account {
    &__content {
      width: 100%;
      height: 100%;
    }
  }
}
