.second-input {
  width: 100%;

  &__container {
    display: block;

    position: relative;
  }

  &__label {
    display: block;
    font-size: 14px;
    line-height: 21px;
    color: #30303A;

    margin-bottom: 2px;

    &_color-pink {
      color: rgba(244, 83, 132, 0.7);
    }
  }

  &__input {
    width: 100%;

    background-color: #E8EBF6;
    border: 1px solid #B0B7D3;
    border-radius: 8px;
    outline: none;

    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #30303A;

    transition: all 0.3s linear;
    box-sizing: border-box;

    padding: 8px 12px;

    &:focus {
      border: 1px solid #4661C4;
      background-color: rgba(232, 235, 246, 0.3);
    }

    &::placeholder {
      color: rgba(48, 48, 58, 0.7);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__error {
    display: block;

    position: absolute;

    opacity: 0;

    transition: opacity 0.3s linear;

    font-size: 12px;
    line-height: 18px;
    color: #F7526D;

    &_active {
      opacity: 1;
    }
  }
}

