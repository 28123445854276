.tab-menu {
  &__bottom-navigation {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    min-height: 52px;
    background-color: #4661C4;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0px;
  }
}

@media (max-width: 630px) {
  .tab-menu {
    &__bottom-navigation {
      display: block;
      z-index: 3;
    }
  }

  jdiv jdiv jdiv jdiv[style^="background"][class^="button"] {
    opacity: 0;
  }
}
