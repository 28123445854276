.user-manual__container {
   height: 100vh;

   display: flex;
   justify-content: center;
   align-items: center;
   
   .user-manual__button {
      width: 250px; 
      height: 60px;

      text-align: center; 
      white-space: normal; 
      padding: 15px 15px !important;
      
      .button__text {
         font-size: 18px !important;  
         line-height: 1.33 !important;
         font-weight: 500 
      }
      
      &:hover {
         background-color: #3652B8;
      }
   }
}
