.pwa-banner {
  &__container {
    width: 330px;
    height: min-content;

    font-family: 'Jost', sans-serif;

    background-color: #fff;
    border-radius: 8px;

    padding: 24px 32px;
  }

  &__icon {
    width: 64px;
    max-width: 64px;
    max-height: 64px;

    margin: 0 0 24px;
  }

  &__content {

  }

  &__label {
    color: #1D1D2D;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 8px;
  }

  &__text {
    color: #5F5F6B;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
  }
}

@media (max-width: 920px) {
  .pwa-banner {
    &__container {
      width: auto;
      max-width: 314px;
    }
  }
}