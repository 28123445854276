.eko-popup {
  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__container {
    width: 300px;

    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;

    margin: 0 0 16px;
  }
}

.completed-activation-card__icon {
  width: 40px;
  height: 40px;
  
  margin: 0 0 16px;
}

.long-no-active__icon {
  width: 40px;
  height: 40px;
  max-width: 40px;

  margin: 0 0 16px;

  .svg-icon path {
    fill: red;
  }
}

@media (max-width: 768px) {
  .eko-popup {
    &__popup-content {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 350px) {
  .eko-popup {
    &__title {
      font-size: 22px;
    }
  }
}
