.activation-card {
  &__container {
    max-width: 313px;
    width: 100%;

    margin: 0 auto;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 130%;
    color: #1D1D2D;

    margin: 0 0 8px;
  }

  &__description {
    font-size: 16px;
    line-height: 121%;
    color: #6F6F76;

    margin: 0 0 24px;
  }

  &__error {
    height: 40px;

    display: block;

    font-size: 12px;
    line-height: 18px;
    color: #ff0000;

    transition: opacity 0.3s linear;

    opacity: 0;

    margin: 0 0 12px;

    &_active {
      opacity: 1;
    }
  }

  &__tip {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-decoration-line: underline;
    color: #B0B7D3;

    margin: 0;
  }
}

@media (max-width: 768px) {
  .activation-card {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 480px) {
  .activation-card {

    .popup__children {
      margin-top: 70px;
      align-items: flex-start;
    }
  }
}
