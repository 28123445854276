.empty-basket {

  &__button {
    width: 261px;
  }

  &__info {
    font-size: 22px;
    line-height: 32px;
    color: #4661C4;

    margin: 0 0 4px;
  }

  &__prompt {
    font-size: 18px;
    line-height: 160%;
    color: #30303A;

    margin: 0 0 18px;
  }
}

@media (max-width: 420px) {
  .empty-basket {

    &__button {
      width: 100%;

      .button__text {
        font-size: 12px;
      }
    }

    &__info {
      font-size: 14px;
    }

    &__prompt {
      font-size: 12px;
    }
  }
}
