.change-user-data {
  .popup {
    &__content {
      max-width: 460px;
    }
  }

  &__container {
    max-width: 400px;

    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  &__second-input {
    max-width: 400px;

    .second-input__error {
      max-width: 260px;

      position: relative;
    }
  }

  .timer__link {
    width: fit-content;

    cursor: pointer;
  }

  .input__input {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .change-user-data {
    width: 100%;
    height: 100%;

    align-items: center;
  }
}
