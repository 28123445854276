.default-delivery {
  display: flex;
  justify-content: space-between;

  margin-top: 24px;
}

.default-delivery__form-container {
  margin-right: 40px;
}

.default-delivery__title {
  font-size: 18px;
  font-weight: 400;
  color: #27AE60;

  margin: 0 0 24px;
}

.default-delivery__info-container {
  max-width: 515px;
  height: fit-content;

  background-color: #fff;
  border: 1px solid #4661C4;
  border-radius: 24px;

  padding: 24px;
}

.default-delivery__text {
  font-size: 16px;
  color: #676767;

  margin: 0 0 4px;
}

.default-delivery__item {
  font-size: 16px;
  font-weight: 700;
  color: #676767;

  margin: 0 0 4px;
}

.default-delivery__list {
  margin: 0;
  padding-left: 30px;
}

@media (max-width: 1100px) {
  .default-delivery {
    flex-direction: column-reverse;
  }

  .default-delivery__form-container {
    margin-right: 0;
  }

  .default-delivery__info-container {
    max-width: none;

    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .default-delivery__info-container {
    padding: 20px;
  }

  .default-delivery__title {
    font-size: 16px;
  }

  .default-delivery__text, .default-delivery__item {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .default-delivery__title {
    font-size: 15px;
  }

  .default-delivery__text, .default-delivery__item {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .default-delivery__info-container {
    padding: 16px;
  }

  .default-delivery__text, .default-delivery__item {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .default-delivery__text, .default-delivery__item {
    font-size: 11px;
  }
}