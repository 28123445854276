.input {
  max-width: -webkit-fill-available;
  background: #E8EBF6;
  border: 1px solid #B0B7D3;
  border-radius: 8px;

  padding: 10px 0 2px 20px;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: block;

    font-size: 12px;
    line-height: 18px;
    color: #30303A;

    margin-bottom: 2px;

    &_color-red {
      color: #ff0000;
    }
  }

  &__input {
    background-color: inherit;

    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #30303A;

    outline: none;
    border: none;

    &_error {
      color: #FF4B40;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 24px;
      line-height: 27px;
      color: rgba(48, 48, 58, 0.7);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }
}
