@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.ndfl-banner {
  display: flex;
  flex-direction: row;

  background-color: #F6F9E8;
  border-radius: 8px;

  cursor: pointer;

  padding: 16px 29px 16px 29px;
  margin: 0 0 24px;

  &__text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 20px;
  }

  &__title {
    font-family: Jost;
    font-size: 24px;
    font-weight: 600;
    line-height: 27px;
    color: #F7526D;
  }

  &__info {
    color: #30303A;
    font-family: Jost;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
  }

  &__link {
    font-family: Jost;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #4661C4;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .ndfl-banner {
    flex-direction: column;
    padding: 10px;
    align-items: center;

    &__image {
      width: 100px;
    }
  }
}

@media (max-width: 560px) {
  .ndfl-banner {
    flex-direction: column;
    align-items: center;
    padding: 10px 19px 10px 19px;
    justify-content: center;

    &__text-container {
      margin-left: 0;
    }

    &__title {
      text-align: center;
      font-size: 18px;
    }

    &__info, &__link {
      display: none;
    }
    &__image {
      width: 89px;
    }
  }
}

@media (max-width: 560px) {
  .ndfl-banner {
    margin: 0 0 16px;
  }
}
