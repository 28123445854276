.user-field {
  &__specify-email {
    color: #4661C4;
    cursor: pointer;
    border-bottom: 1px solid;
    border-radius: 0 !important;
    height: 20px !important;
    margin-bottom: 7px;
    margin-top: 10px!important;
  }

  &__container {
    width: fit-content;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__info-container {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }

  &__data {
    height: 45px;
    margin-top: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 18px;
  }

  &__icon {
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 20px;
  }
}
