.pwa-info {
  &__container {
    font-family: 'Jost', sans-serif;
  }
  
  &__main-text {
    color: #5F5F6B;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

    margin: 0 0 32px;
  }

  &__ios-content {
    margin: 0 0 56px;
  }

  &__title {
    color: #30303A;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;

    margin: 0 0 24px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-template-rows: auto;
    gap: 20px;

    list-style: none;

    padding: 0;
    margin: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex: 1;

    border-radius: 8px;
  }

  &__img {
    border-radius: 8px;

    margin: 0 0 16px;
  }

  &__step-title {
    color: #30303A;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;

    margin: 0 0 8px;
  }

  &__step-text {
    color: #5F5F6B;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    
    margin: 0;
  }
}