.setting-popup {
  &__main-content {
    width: 100%;
    height: 100%;

    padding: 0;

    .popup__close {
      display: none;
    }
  }

  &__icon-wrapper {
    padding: 0 20px 0 0;
  }

  &__close {
    width: 30px;
		height: 30px;
    max-width: 30px;

    cursor: pointer;
    transition: all 0.3s linear;
    
    &:hover {
      transform: rotate(120deg);
    }
  }

  &__filter-group {
    width: 100%;

    background-color: #E8EBF6;
  }

  &__button {
    color: #4661C4;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    position: relative;

    padding: 0 0 0 20px;
  }

  &__button-title {
    height: 100%;
    text-decoration: underline;
  }

  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
  }

  &__filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin: 20px;
  }

  &__filter-item {
    padding: 3px 7px 3px 7px;
    border-radius: 4px;
    font-size: 12px;
    background-color: #fff;
    color: #93939a;
    width: fit-content;
    margin-right: 8px;
    margin-bottom: 8px;

    &_active {
      color: #fff;
      background-color: #4661C4;
    }
  }

  &__filter-title {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 400;
  };

  &__filter-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__button-container {
    width: 100%;

    display: flex;
    justify-content: center;

    background-color: #E8EBF6;

    padding: 16px 0;
  }

  &__button-component {
    .button__text {
      font-weight: 400;
    }
  }

  &__header {
    width: 100%;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px 0;
  }

  &__header-title {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
