.header-prizes {
  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 1;
    color: #1D1D2D;

    margin: 0 0 24px;
  }

  &__catalog-counter {
    font-size: 10px;
    color: #6F6F76;
    display: none;
  }

  &__selector-container {
    &_mobile {
      display: none;
    }
  }

  &__icon-container {
    margin-top: 22px;
    margin-left: 14px;
    margin-right: 3px;
    align-items: center;
    height: 45px;
    justify-content: center;
  }

  &__setting-icon {
    width: 36px;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 1;
    color: #5F5F6B;

    margin: 0 0 24px;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__list-purse {
    max-width: 100%;

    display: flex;
    align-items: center;

    overflow-x: auto;

    list-style: none;

    padding: 0;
    margin: 0 0 24px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #4661C4;
    }

    &[data-is-scroll='true'] {
      .header-prizes__purse {
        margin: 0 8px 8px 0;
      }
    }
  }

  &__purse {
    min-width: 180px;

    position: relative;

    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;

    transition: background-color 0.3s linear;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

    cursor: pointer;

    padding: 14px 16px;
    margin: 0 8px 0 0;

    &_active {
      background-color: #4661C4;

      .header-prizes__name-purse {
        color: rgba(255, 255, 255, 0.7);
      }

      .header-prizes__balance {
        color: #fff;
      }
    }
  }

  &__name-purse {
    display: block;

    font-size: 11px;
    line-height: 140%;
    color: #6F6F76;

    transition: color 0.3s linear;

    margin-bottom: 4px;
  }

  &__balance {
    display: block;

    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #F45384;

    transition: color 0.3s linear;
  }

  &__tip {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__info-container {
    display: flex;
    align-items: flex-end;

    margin-bottom: 24px;
  }

  &__row {
    display: flex;
  }

  &__categories {
    margin-right: 12px;
  }

  &__availability {
    margin-left: 12px;
  }

  &__search-container {
    margin-top: 12px;
    width: 100%;
  }

  &__purse-loading {
    box-sizing: border-box;
    position: relative;
    min-height: 50%;
    padding-top: 0;
  }
}

@media (max-width: 1100px) {
  .header-prizes {
    &__row {
      width: 100%;
    }

    &__info-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__categories {
      width: 100%;
      margin-bottom: 8px;
      margin-right: 0;
    }

    &__search-container {
      margin-top: 8px;
    }

    &__availability {
      width: 100%;

      margin-left: 0;
      margin-top: 8px;
    }
  }
}

@media (max-width: 897px) {
  .header-prizes {
    &__catalog-counter {
      display: block;

      font-size: 12px;
      line-height: 1;

      margin: 0 0 16px;
    }

    &__selector-container {
      &_desktop {
        display: none;
      }

      &_mobile {
        display: flex;
      }
    }

    &__row {
      width: 100%;

      display: none;
    }

    &__info-container {
      flex-direction: row;
      align-items: flex-start;
    }

    &__search-container {
      margin: 0;
    }

    &__icon-container {
      margin: 0 3px 0 14px;
    }
  }
}

@media (max-width: 670px) {
  .header-prizes {
    &__availability {
      margin-left: 0;
    }
  }
}

@media (max-width: 620px) {
  .header-prizes {
    &__title {
      font-size: 35px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__content {
      overflow-x: auto;

      &::-webkit-scrollbar {
        display:none;
      }
    }

    &__list-purse {
      max-width: 100%;
      width: 100%;
    }

    &__purse {
      max-width: 50%;
    }
  }
}

@media (max-width: 500px) {
  .header-prizes {
    &__title {
      margin: 0 0 16px;
    }

    &__subtitle {
      margin: 0 0 16px;
    }

    &__list-purse {
      margin: 0 0 16px;
    }
  }
}

@media (max-width: 470px) {
  .header-prizes {
    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 12px !important;
      letter-spacing: -0.5px;
    }
  }
}

@media (max-width: 430px) {
  .header-prizes {
    &__content {
      display: block;
    }

    &__list-purse {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 360px) {
  .header-prizes {
    &__title {
      font-size: 25px;
    }
  }
}
