.history-eko {
  &__container {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
  }

  &__description {
    font-size: 18px;
    margin-bottom: 15px;
  }

  &__title-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid #f6f6f9;
    padding-bottom: 5px;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .button {
      background-color: #fff;
      padding: 8px 16px;

      &__text {
        font-size: 16px;
        text-transform: none;
        font-weight: 400;
        color: #000;
      }

      &__container {
        flex-direction: row-reverse;
      }

      &__icon {
        margin: 0 0 0 4px;
        width: 15px;
      }
    }
  }

  &__nothing{
    color: #b1b3bb;
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: lighter;
  }

  &__info {
    min-width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }

  &__phone {
    min-width: 145px;
  }

  &__info-text {
    font-size: 12px;

    &_mobile {
      display: none;
    }
    &_desktop {
      display: block;
      width: 67px;
    }
  }

  &__info-number {
    width: 118px;
  }

  &__info-date {
    width: 180px;
    text-align: right;
  }
}

@media (max-width: 820px) {
  .history-eko {
    &__info-number {
      width: 90px;
    }
    &__info-date {
      width: 53px;
    }

    &__info {
      min-width: 45px;
    }

    &__info-text {
      font-size: 10px;

      &_mobile {
        display: block;
      }
      &_desktop {
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .activation-history {
    &__info-text {
      font-size: 8px;
    }

    &__description {
      font-size: 14px;
    }

    &__button-container {
      .button {
        &__text {
          font-size: 10px;
        }
      }
    }
  }
}
