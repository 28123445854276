.tip {
  width: 250px;

  background: #fff;
  border: 1px solid #B0B7D3;
  box-shadow: 0 12px 20px rgba(46, 63, 83, 0.1);
  border-radius: 8px;

  padding: 14px 16px;

  transition: opacity 0.4s linear;

  position: absolute;

  z-index: -1;
  opacity: 0;

  &__show {
    z-index: 10;
    opacity: 1;
  }
}

@media (max-width: 360px) {
  .tip {
    width: 200px;
  }
}