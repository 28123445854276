@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
.ndfl {
  &__banner {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 24px 32px;
    width: 302px;
    display: flex;
    flex-direction: column;
  }

  &__banner-image {
    width: 89px;
  }

  &__banner-link {
    font-family: Jost;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #4661C4;
    cursor: pointer;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  &__banner-text {
    font-size: 14px;
    font-family: Jost;
    line-height: 16.8px;
  }

  &__banner-title {
    text-align: left;
    line-height: 20.8px;
    font-size: 16px;
    font-family: Jost;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 18px;
    line-height: 160%;
    color: #5F5F6B;
    font-family: Jost;

    margin: 0 0 16px;
  }

  &__color {
    &_red {
      color: #F7526D;
    }
  }

  &__button {
    width: 212px;
    border-radius: 8px;
    margin-top: 15px;
  }

  &__warning-message {
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Jost;
    width: 463px;
    margin-top: 15px;
  }

  &__table {
    max-width: 520px;
    display: flex;
    flex-direction: column;
  }

  &__status-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__reload-button {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    cursor: pointer;
    height: 26px;

    &:hover {
      background-color: #fcfcfc;
    }

    &_pressed {
     opacity: 0.5;

      rect {
        fill: #30303a;
        border: 1px solid #30303a;
      };
    }
  }

  &__status {
    color: #b6b6b6;
    display: flex;
    gap: 5px;
    align-items: center;

    &_ready {
      color: #40C179;
    }

    &_reject {
      color: #F7526D;
    }

    &_wait {
      color: #4661C4;
    }
  }

  &__data-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    border-bottom: 1px solid rgba(176, 183, 211, 0.6);
    min-height: 37px;
  }

  &__data-head {
    font-size: 14px;
    color: #6F6F76;
    width: 160px;
  }

  &__data-body {
    width: 100%;
  }

  &__body {
    color: #30303A;
    font-size: 14px;
  }
}

@media (max-width: 1060px) {
  .ndfl {
    &__container {
      flex-direction: column;
    }

    &__banner {
      margin-top: 40px;
    }
  }
}

@media (max-width: 768px) {
  .ndfl {
    &__data-row {
      font-size: 12px;
      gap: 0;
    }

    &__warning-message {
      width: 320px;
      gap: 10px;
      padding: 8px;
    }

    &__data-head {
      font-size: 12px;
      width: 110px;
    }

    &__banner-title {
      font-size: 14px;
    }

    &__banner-text {
      font-size: 12px;
    }

    &__banner-image {
      width: 40px;
    }

    &__banner-link {
      font-size: 12px;
    }

    &__banner {
      padding: 16px;
    }
  }
}
