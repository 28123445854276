.timer {
  height: 48px;

  font-size: 16px;
  margin-bottom: 93px;

  &__counter {
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    margin: 0;
  }

  &__button {
    border: 1px solid rgb(70, 97, 196);
    margin-top: 20px;
  }

  &__link {
    display: block;

    line-height: 24px;
    color: rgba(70, 97, 196, 0.7);

    opacity: 0;

    transition: opacity 0.3s linear;

    text-decoration-line: underline;

    &_active {
      opacity: 1;
    }
  }
}
