.inner-tabs {
  &__container {
    display: flex;
    list-style: none;

    border-bottom: 1px solid #B0B7D3;
  }

  &__item {
    font-size: 20px;
    line-height: 150%;
    color: rgba(29, 29, 45, 0.6);

    border-bottom: 2px solid transparent;

    cursor: pointer;

    transition: color 0.3s linear, border-bottom 0.3s linear;

    margin: 0 48px -1px 0;

    &:last-child {
      margin-right: 0;
    }

    &_active {
      color: #4661C4;
      border-bottom: 1.5px solid #4661C4;
    }
  }

  &__content {
    margin-top: 11px;
  }
}

@media (max-width: 768px) {
  .inner-tabs {
    &[data-mobile-wrapper='true'] {
      .inner-tabs__container {
        overflow: hidden;
        overflow-x: scroll;
        scroll-behavior: smooth;

        padding: 0 50px;
      }
      
      .inner-tabs__container::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .inner-tabs__item {
        text-wrap: nowrap;

        &_active {
          scroll-snap-align: center;
        }
      }
      .inner-tabs__content {
        padding: 0 50px 72px;
      }
    }
  }
}

@media (max-width: 630px) {
  .inner-tabs {
    &[data-mobile-wrapper='true'] {
      .inner-tabs__container {
        padding: 0 20px;
      }

      .inner-tabs__content {
        padding: 0 20px 72px;
      }
    }
  }
}

@media (max-width: 600px) {
  .inner-tabs {
    &__item {
      font-size: 13px;
      margin: 0 20px -1px 0;
      line-height: 19.9px;
    }
  }
}

@media (max-width: 500px) {
  .inner-tabs {
    &[data-mobile-wrapper='true'] {
      .inner-tabs__content {
        padding: 0 20px 0;
      }
    }
  }
}


@media (max-width: 400px) {
  .inner-tabs {
    &__item {
      margin: 0 25px -1px 0;
    }
  }
}

