.menu {
  display: none;

  &__container {
    width: 100vw;
    height: 100vh;

    background-color: rgba(48, 48, 58, .9);

    align-items: center;
    justify-content: flex-start;

    transition: all 0.3s ease-in;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    margin-left: -100vw;

    &_active {
      margin-left: 0;
    }
  }

  &__burger {
    display: none;

    transform: scale(1, 1);
    cursor: pointer;
    margin-top: 10px;
  }


  &__cross {
    width: 16px;
    height: 16px;
    max-width: 16px;

    margin-top: 10px;
  }

  &__content {
    width: 30%;
    height: 100vh;

    list-style: none;

    background-color: #fff;

    transition: margin-left 0.3s linear;

    padding-top: 40px;
    padding-right: 40px;
    margin-top: 0;
    margin-left: -60%;

    &_active {
      margin-left: 0 !important;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  &__logo {
    margin-right: 16px;
  }

  &__item {
    display: flex;
    align-items: center;

    cursor: pointer;

    margin-bottom: 16px;
  }

  &__active-card {
    display: flex;
  }

  &__icon {
    width: 25px;
    height: 25px;

    margin-right: 13px;
  }

  &__text {
    font-size: 18px;
    line-height: 160%;
    color: #5F5F6B;

    transition: color 0.3s linear;

    &:hover {
      color: #4661C4;
    }
  }

  &__nav-menu {
    display: block;
  }
}

@media (max-width: 1100px) {
  .menu {
    display: block;

    &__burger {
      display: block;
    }
  }
}

@media (max-width: 780px) {
  .menu {
    &__content {
      width: 40%;

      margin-left: -75%;
    }

    &__content {
      padding-right: 30px;
    }

    &__text {
      span {
        color: #F45384;
      }
    }
  }
}

@media (max-width: 630px) {
  .menu {
    &__content {
      width: 45%;

      margin-left: -80%;
    }

    &__active-card {
      display: flex;
    }
  }
}

@media (max-width: 600px) {
  .menu {
    display: block;

    &__text-help {
      margin-bottom: 40px;
    }

    &__content {
      width: 60%;

      margin-left: -95%;
    }
  }
}

@media (max-width: 500px) {
  .menu {

    &__content {
      width: 70%;

      margin-left: -95%;
      padding-left: 0;
      padding-right: 0;

      &_active {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    &__nav-menu {
      display: block;
    }

    &__text {
      font-size: 14px;

      &_gray {
        color: #B0B7D3;
      }

      &_black {
        color: #000;
      }
    }
  }
}


@media (max-width: 370px) {
  .menu {
    &__content {
      margin-left: -90%;
    }
  }
}

@media (max-width: 320px) {
  .menu {
    &__content {
      width: 100%;

      margin-left: -90%;
    }
  }
}
