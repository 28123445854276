@font-face {
  font-family: 'Circe';
  src: url('../../../../../assets/fonts/Circe-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.insufficient-data {
  .button {
    width: 100%;
    border-radius: 8px;
    margin-top: 10px;

    &__text {
      color: #FFF;
      font-family: 'Circe';
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__container {
    width: 300px;

    text-align: center;
  }

  &__icon {
    margin-bottom: -10px;
  }

  &__title {
    color: #FF4B40;
    text-align: center;
    font-family: 'Circe';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 116.667% */
  }

  &__subtitle {
    color: #30303A;
    text-align: center;
    font-family: 'Circe';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .insufficient-data {
    &__popup-content {
      width: 100%;
      height: 100%;

      align-items: center;
    }
  }
}

@media (max-width: 460px) {
  .order-list {
    &__item {
      font-size: 14px;
    }
  }
}

@media (max-width: 350px) {
  .completed-order {
    &__title {
      font-size: 22px;
    }
  }
}
