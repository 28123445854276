.button {
  height: 46px;

  display: inline-flex;
  justify-content: center;

  background-color: #4661C4;
  border-radius: 4px;
  box-sizing: border-box;

  cursor: pointer;

  transition: all 0.5s linear;

  padding: 11px 5px;

  &__container {
    display: inline-flex;
    align-items: center;
  }

  &:hover {
    background-color: #3652B8;
  }

  &_click {
    background: rgba(70, 97, 196, 0.7);
  }

  &__animation {
    &-enter-active {
      animation: fade-in-button 0.6s linear;
    }

    &-exit-active {
      animation: fade-out-button 0.6s linear;
    }
  }

  &__icon {
    margin-right: 8px;

    &_margin-left {
      margin-left: 8px;
    }
  }

  &__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #fff;

    &_uppercase {
      text-transform: uppercase;
    }
  }
}

@media (max-width: 630px) {
  .button {
    height: 40px;

    &__text {
      font-size: 14px;
    }
  }
}

@keyframes fade-in-button {
  0%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out-button {
  0%{
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
