.menu-cart {
  display: flex;
  align-items: center;

  cursor: pointer;

  &__image {
    margin-right: 4px;
    width: 32px;
    height: 32px;
  }

  &__faint {
    font-size: 14px;
    line-height: 26px;
    color: #B0B7D3;

    margin: 0 4px 0 0;
  }

  &__sum {
    font-size: 14px;
    line-height: 26px;
    color: #4661C4;

    white-space: nowrap;

    margin: 0 32px 0 0;
  }
}
