.place-order {
  margin-bottom: 208px;

  &__step-back-container {
    display: flex;
    align-items: center;

    cursor: pointer;
  }

  &__button {
    width: 213px;
  }

  &__email {
    display: flex;
    align-items: center;
  }

  &__arrow {
    margin-right: 8px;
  }

  &__step-back {
    font-size: 16px;
    line-height: 24px;
    color: #6F6F76;
  }

  &__subtitle {
    font-size: 22px;
    line-height: 150%;
    color: #4661C4;

    margin: 0 0 20px;
  }

  .second-input {
    max-width: 313px;
    width: 100%;
  }

  &__tip {
    max-width: 480px;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #30303A;

    margin: 0 0 16px;

    &_blue {
      color: #4661C4;
    }
  }

  &__link {
    font-weight: 600;
    color: #4661C4;

    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .place-order {
    &__subtitle {
      font-size: 20px;
    }

    &__button {
      width: 100%;

      .button {
        &__text {
          font-size: 14px;
        }
      }
    }

    &__email {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: 480px) {
  .place-order {
    margin-bottom: 70px;

    &__subtitle {
      font-size: 18px;
    }
  }
}

@media (max-width: 430px) {
  .place-order {
    &__subtitle {
      font-size: 14px;
    }
  }
}

@media (max-width: 350px) {
  .place-order {
    margin-bottom: 30px;
  }
}
