.banner-pepsico {
  background-color: #e8ebf8;
  border: 1px solid #4661C4;
  border-radius: 8px;
  
  color: #4661C4;
  font-weight: 700;
  font-size: 14px;

  padding: 15px;
  margin: 0 0 24px;

  &__link {
    color: #4661C4;
    margin-left: 10px;
  }
}