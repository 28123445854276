.modal-open {
  overflow: hidden;
}

/* CLASSIC */

.modal__transitions-classic-enter {
	opacity: 0;
}

.modal__transitions-classic-enter .modal {
	transform: scale(0.3);
}

.modal__transitions-classic-enter-active {
	opacity: 1;
	transition: opacity linear 0.3s;
}

.modal__transitions-classic-enter-active .modal {
	transform: scale(1);
	transition: transform linear 0.3s;
}

.modal__transitions-classic-exit {
	opacity: 1;
}

.modal__transitions-classic-exit .modal {
	transform: scale(1);
}

.modal__transitions-classic-exit-active {
	opacity: 0;
	transition: opacity linear 0.3s;
}

.modal__transitions-classic-exit-active .modal {
	transform: scale(0.3);
	transition: transform linear 0.3s;
}

/* MOBILE_BOTTOM */

.modal__transitions-mobile_bottom-enter {
	opacity: 0;
}

.modal__transitions-mobile_bottom-enter .modal {
	transform: translateY(300px);
}

.modal__transitions-mobile_bottom-enter-active {
	opacity: 1;
	transition: opacity linear 0.3s;
}

.modal__transitions-mobile_bottom-enter-active .modal {
	transform: translateY(0);
	transition: transform linear 0.3s;
}

.modal__transitions-mobile_bottom-exit {
	opacity: 1;
}

.modal__transitions-mobile_bottom-exit .modal {
	transform: translateY(0);
}

.modal__transitions-mobile_bottom-exit-active {
	opacity: 0;
	transition: opacity linear 0.3s;
}

.modal__transitions-mobile_bottom-exit-active .modal {
	transform: translateY(300px);
	transition: transform linear 0.3s;
}

/* MOBILE_BOTTOM-FULL */

.modal__transitions-mobile_bottom-full-enter {
	opacity: 0;
}

.modal__transitions-mobile_bottom-full-enter .modal {
	transform: translateY(700px);
}

.modal__transitions-mobile_bottom-full-enter-active {
	opacity: 1;
	transition: opacity linear 0.3s;
}

.modal__transitions-mobile_bottom-full-enter-active .modal {
	transform: translateY(0);
	transition: transform linear 0.3s;
}

.modal__transitions-mobile_bottom-full-exit {
	opacity: 1;
}

.modal__transitions-mobile_bottom-full-exit .modal {
	transform: translateY(0);
}

.modal__transitions-mobile_bottom-full-exit-active {
	opacity: 0;
	transition: opacity linear 0.3s;
}

.modal__transitions-mobile_bottom-full-exit-active .modal {
	transform: translateY(700px);
	transition: transform linear 0.3s;
}

.popup__container {
  position: fixed;
  inset: 0;

  z-index: 1000;
}

.popup__background {
  display: flex;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: #1D1E22CC;
  
  z-index: -1;
}

.popup__wrapper {
	height: 100%;
	
	display: flex;
	justify-content: space-around;
}

.popup__content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-self: center;
	position: relative;

	box-sizing: border-box;
	
	font-size: 20px;
	background: #fff;
	border-radius: 16px;
	
	z-index: 6;
	
	padding: 48px 48px 33px
}

.popup__close {
    width: 40px;
    height: 40px;
    max-width: 40px;
    
    position: absolute;
    top: 16px;
    right: 16px;
    
    cursor: pointer;
    
    transition: all 0.3s linear;

		z-index: 10;
    
    &:hover {
      transform: rotate(120deg);
    }
}

.popup {
	&__invisible-header {
		display: none;
	}

	&__invisible-footer {
		display: none;
	}
}

.draggableHandle {
  width: 100%;
  
  margin: 0 0 20px;
}

.draggableRect {
  width: 64px;
  height: 5px;

  background: #CACFDD;
  border-radius: 100px;

  margin: 0 auto;
}

@media (max-width: 897px) {
	.popup {
		&__container[data-visible-header ='true'] {
			.popup__invisible-header {
				max-height: 50px;
				height: 100%;
				width: 100%;
				display: block;

				padding: 0 0 20px;
			}
	
			.popup__invisible-footer {
				max-height: 20px;
				height: 100%;
				width: 100%;

				display: block;
			}
		}

		&__content {
			justify-content: flex-start;
		}
	}
}

@media (max-width: 897px) and (orientation: landscape) {
  .popup {
		&__container[data-visible-header ='true'] {
			.popup__invisible-header {
				max-height: 0;
	
				padding: 20px 0 0;
			}
	
			.popup__invisible-footer {
				max-height: 0;
				
				padding: 0 0 20px;
			}
		}
  }
}

@media only screen and (min-width: 100px) and (max-width: 768px) {
	.popup {
		&__content {
			width: 330px;

			border-radius: 0;

			padding: 0 20px;
		}
		
		&__close-icon {
			width: 30px;
			height: 30px;
		
			top: 10px;
			right: 10px;
		}
	}
}
