.prizes-cards {
  min-height: 100vh;

  position: relative;

  &__button-icon {
    .button {
      &__text {
        line-height: 0;
      }
    }
  }

  &__in-cart-icon {
    content: url("./assets/image/inCart.svg");
    width: 24px;
  }

  &__button-container {
    display: none;

    .button {
      background-color: #E8EBF6;

      &__text {
        color: #000;
      }

      &__container {
        flex-direction: row-reverse;
      }

      &__icon {
        margin: 0 0 0 4px;
        height: 18px;
      }
    }
  }

  &__loading {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;

    transition: opacity 0.5s linear;

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;

    padding-top: 150px;

    &_active {
      opacity: 1;
      z-index: 2;
    }
  }

  &__button {
    width: 100%;
    background: #E8EBF6;
    height: 38px;

    .button {
      &__text {
        font-weight: 600;
        color: #4661C4;
        font-size: 12px;
      }
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    transition: opacity 0.3s;

    opacity: 1;

    margin: 0;
    padding: 0;

    &_hide {
      opacity: 0;
    }
  }

  &__nothing{
    color: #b1b3bb;
    text-transform: uppercase;
    font-weight: lighter;
  }

  &__item {
    max-width: calc(25% - 7px);
    width: 100%;

    cursor: pointer;

    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
    border-radius: 12px;
    box-sizing: border-box;

    transition: all 0.5s linear;
    opacity: 0;

    padding: 14px;
    margin: 0 8.5px 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:nth-child(4n + 4) {
      margin-right: 0;
    }

    &:hover {
      border: 1px solid #4661C4;
      box-shadow: 0 4px 20px rgba(50, 60, 114, 0.4);

      .in-cart-icon {
        &__cart {
          fill: #fff;
        }

        &__check {
          fill: #404850;
        }
      }

      .prizes-cards__in-cart-icon {
        content: url("./assets/image/inCartHover.svg");
      }

      .prizes-cards__image{
        box-shadow: 0 4px 20px rgba(50, 60, 114, 0.4);
      }

      .prizes-cards__button {
        background-color: #4661C4;
        transition: all 0.5s linear ;

        .button__text {
          color: #fff;
        }
      }
    }

    &_active {
      opacity: 1;
    }
  }

  &__item-container {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image-container {
    position: relative;
  }

  &__plastic-tip {
    background: linear-gradient(180deg, #5C7CEF 29.89%, #364EA4 106.6%);
    border-radius: 4px;

    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;

    font-size: 12px;
    color: #fff;

    padding: 0 6px;
  }

  &__image {
    width: 100%;
    height: auto;

    position: relative;
    z-index: 0;

    background: #FFFFFF;
    border: 1px solid #E8EBF6;
    border-radius: 8px;
    transition: all 0.5s linear;
    margin-bottom: 19px;
  }

  &__title {
    font-size: 22px;
    line-height: 22px;
    color: #31316e;
    text-overflow: ellipsis;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0 0 10px;
  }

  &__subtitle {
    font-size: 12px;
    color: #727272;
    margin-bottom: 15px;

    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;

    &_desktop {
      display: block;
    }

    &_mobile {
      display: none;
    }
  }

  &__category {
    color: #4661C4;
    cursor: pointer;
  }

  &__description-list {
    list-style: none;

    margin: 0 0 6px;
    padding: 0;
  }

  &__content {
    margin: 0 10px;
  }

  &__link-container {
    margin-bottom: 4px;
  }

  &__link {
    display: inline-block;

    font-size: 13px;
    line-height: 160%;
    color: #4661C4;

    word-break: break-all;
    text-decoration: none;
  }

  &__description-label {
    font-size: 13px;
    line-height: 150%;
    color: rgba(48, 48, 58, 0.7);
  }

  &__years {
    display: inline-block;
    font-size: 13px;
    line-height: 150%;
    color: #30303A;
  }

  &__button {
    &_mobile {
      display: none;
    }
  }
}

@media (max-width: 1100px) {
  .prizes-cards {
    &__item {
      max-width: calc(33% - 6px);

      &:nth-child(4n + 4) {
        margin-right: 8px;
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
  .no-wallet-modal{
    &__body{
      width: 30%;
    }
  }

}

@media (max-width: 920px) {
  .prizes-cards {
    &__button {
      .button__text {
        font-size: 13px;
      }
    }
  }
  .no-wallet-modal{
    &__body{
      width: 40%;
    }
  }
}

@media (max-width: 850px) {
  .prizes-cards {
    &__button {
      .button__text {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 800px) {
  .prizes-cards {
    &__button {
      .button__text {
        font-size: 13px;
      }
    }
  }
  .no-wallet-modal{
    &__body{
      width: 50%;
    }
  }
}

@media (max-width: 780px) {
  .prizes-cards {
    justify-content: space-between;

    padding: 0 5px;

    &__item {
      max-width: calc(50% - 6px);

      &:nth-child(4n + 4) {
        margin-right: 0;
      }

      &:nth-child(3n + 3) {
        margin-right: 8.5px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    &__button {
      .button__text {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 780px) {
  .prizes-cards {
    padding: 0;
  }
}

@media (max-width: 540px) {
  .prizes-cards {
    &__button {
      .button__text {
        font-size: 12px;
      }
    }

    &__button-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__item {
      padding: 8px;
    }

    &__title {
      font-size: 12px;
    }
  }
  .no-wallet-modal{
    &__body{
      width: 70%;
    }
  }
}

@media (max-width: 450px) {
  .prizes-cards {
    display: block;

    &__subtitle {
      margin-top: 0;
    }

    &__button-icon {
      svg {
        width: 16px;
      }
    }

    &__in-cart-icon {
      width: 15px;
      margin-top: 5px;
    }

    &__title {
      font-size: 12px;
    }

    &__item-container {
      height: 100%;

      display: flex;
    }

    &__image {
      margin-bottom: 0;
    }
    &__years-container {
      margin-bottom: 0;
    }

    &__years {
      margin-bottom: 0;
    }

    &__button {
      display: none;

      &_mobile {
        display: inline-flex;
        align-items: center;
        height: 20px;
        margin: 0 5px;
        width: calc(100% - 10px);
      }

      .button__text {
        font-size: 8px;
        text-align: center;
      }
    }
  }
  .no-wallet-modal{
    &__body{
      width: 90%;

    }
  }
}

@media (max-width: 400px) {
  .prizes-cards {
    &__title {
      margin-bottom: 16px;
    }

    &__link-container, &__years-container {
      line-height: 1.1;
    }

    &__description-label, &__years, &__link {
      font-size: 9px;
    }
  }
}

@media (max-width: 360px) {
  .prizes-cards {
    &__title {
      margin-bottom: 12px;
    }
  }
}
