.verified-email {
  position: relative;

  cursor: pointer;

  &__timer {
    width: 26px;
  }

  &__button {
    height: 30px;
    border-radius: 8px;
    background-color: #FFFFFF;

    &:hover {
      background-color: #FFFFFF;
    }

    .button {
      &__text {
        font-size: 10px;
        text-transform: none;
        text-decoration: none;
      }
    }

    &_active {
      border: 1px solid  #F45384;

      .button {
        &__text {
          color: #F45384;
        }
      }
    }

    &_no-active {
      border: 1px solid  #838383;

      .button {
        &__text {
          color: #838383;
          display: flex;
          gap: 5px;
        }
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
  }

  &__icon {
    margin-right: 8px;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;

    white-space: nowrap;

    margin-bottom: 5px;

    &_disabled {
      color: #838383;
    }

    &_no-verified {
      border-bottom: 1px solid;
      color: #F45384;
      line-height: 1;
    }

    &_verified {
      color: #27AE60;
    }
  }

  &__tip {
    width: 298px;

    background-color: #fff;
    border: 1px solid #B0B7D3;
    box-shadow: 0 12px 20px rgba(46, 63, 83, 0.1);
    border-radius: 8px;

    transition: opacity 0.3s linear;

    position: absolute;

    z-index: -1;
    opacity: 0;
    right: 0;

    padding: 14px 16px;

    &_active {
      z-index: 1;
      opacity: 1;
    }
  }

  &__info-text {
    font-size: 12px;
    line-height: 15px;
    color: #30303A;

    margin: 0;

    &_first-child {
      display: block;

      margin-bottom: 8px;
    }
  }
}

@media (max-width: 600px) {
  .verified-email {
    margin-bottom: 8px;

    &__tip {
      right: auto;
    }
  }
}

@media (max-width: 400px) {
  .verified-email {
    &__tip {
      width: 240px;
    }
  }
}
