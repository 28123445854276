.tab-item {
  &__title {
    text-align: center;
    margin-top: 5px;
    font-size: 10px;
  }

  &__button-container {
    color: #ffffff;
    background-color: #4661C4;
    width: 25%;
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &_active {
      color: #4661C4;
      background-color: #fff;
    }
  }

  &__button {
    font-size: 16px;
    font-weight: 500;
    padding-right: 2px;
    padding-left: 2px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}
