.link {
  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    .link {
      &__text {
        color: #4661C4;
      }
    }
  }

  &__icon {
    margin-right: 10px;
    width: 26px;
  }

  &__text {
    font-size: 15px;
    line-height: 150%;
    text-decoration-line: none;
    color: #30303A;

    transition: all 0.5s linear;
  }
}

@media (max-width: 500px) {
  .link {
    &__text {
      font-size: 13px;
    }
  }
}
