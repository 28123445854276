.authorizes-menu {
  display: flex;
  align-items: center;

  &_justify-between {
    width: 100%;
    justify-content: space-between;
  }

  &__ndfl {
    position: relative;
    margin-right: 20px;
  }

  &__ndfl-animation {
    &_empty {
      animation: clickMe 2s ease-in-out infinite;
    }

    &_reject {
      animation: clickMe 2s ease-in-out infinite;
    }
  }

  &__ndfl-status {
    position: absolute;
    width: 6px;
    height: 6px;
    right: -2px;
    top: 0;
    border-radius: 50%;

    &_approve {
      background-color: #1BB961;
    }

    &_reject {
      background-color: #F7526D;
    }

    &_moderate {
      background-color: #4661C4;
    }

    &_empty {
      background-color: #F7526D;
    }
  }

  &__info-container {
    display: flex;
    align-items: center;
  }

  &__balance {
    font-size: 15px;
    line-height: 26px;
    color: #F45384;

    white-space: nowrap;

    margin: 0;
  }

  &__button {
    .button__text {
      font-weight: 400;
      color: #fff;
    }
  }

  &__logout {
    cursor: pointer;
  }
}

@media (max-width: 1100px) {
  .authorizes-menu {
    &__info-container {
      display: none;
    }

    &__button {
      display: none;
    }
  }
}

@media (max-width: 630px) {
  .authorizes-menu {
    &__button {
      display: none;
    }
  }
}

@keyframes clickMe {
  0% {
    transform: translateX(0);
  }
  5% {
    transform: translateX(-10px);
  }
  10% {
    transform: translateX(10px);
  }
  15% {
    transform: translateX(-8px);
  }
  20% {
    transform: translateX(8px);
  }
  25% {
    transform: translateX(-6px);
  }
  30% {
    transform: translateX(6px);
  }
  35% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(4px);
  }
  45% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
