@mixin flex($justifyContent, $alignItems) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

.header {
  padding: 20px 0 16px;
  margin-bottom: 72px;

  position: fixed;
  width: 100vw;

  &_show {
    top: 0;
  }

  &_hide {
    top: -100px;
  }

  &__button {
    margin-right: 5px;
  }

  &__help-title {
    color: #B0B7D3;
    font-size: 17px;
    margin-bottom: 10px;
  }

  &__phone-info-container {
    display: flex;
    flex-direction: column;

    font-size: 15px;
    color: #30303a;

    margin: 0 24px 0 0;

    .header__phone {
      white-space: nowrap;
      color: #007CFF;
    }
  }

  &__logo-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: -1;
    margin-left: -20px;
  }

  &__container {
    max-width: 1220px;
    width: calc(100% - 40px);

    &_row-reverse {
      flex-direction: row-reverse;
    }

    @include flex(space-between, center);

    padding: 0 20px;
    margin: 0 auto;
  }

  &__link-group {
    display: none;
  }

  &__link-icon {
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
    display: none;
  }

  &__logo {
    max-width: 264px;
    max-height: 45px;

    cursor: pointer;

    margin-top: -10px;
  }

  &__list {
    list-style: none;

    margin: 0;
    padding: 0;

    @include flex(flex-start, center);
  }

  &__item {
    font-weight: 700;
    font-size: 19px;
    line-height: 26px;
    color: #30303A;

    cursor: pointer;

    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button-container {
    height: 100%;
  }

  &__button {
    .button__text {
      font-weight: 400;
      letter-spacing: 0;
    }
  }

  &__icons-wrapper {
    margin: 0 0 24px;
  }

  &__help-container {
    margin: 0 58px 0 0;

    &[data-is-pepsico='true'] {
      margin: 0 24px 0 0;
    }
  }

  &__button-container,
  &__help-container,
  &__auth-container {
    @include flex(flex-start, center);
  }

  &__jivochat-container, &__telegram-container, &__whatsapp-container, &__pwa-container {
    @include flex(flex-start, center);
  }

  &__jivochat-container {
    margin-right: 24px;
    margin-bottom: 10px;
  }

  &__telegram-container {
    text-decoration: none;

    margin-right: 24px;
    margin-bottom: 10px;
  }

  &__whatsapp-container {
    text-decoration: none;

    margin-bottom: 10px;
  }

  &__pwa-container {
    text-decoration: none;
    
    margin: 0 0 24px;
  }

  &__jivochat-icon, &__telegram-icon, &__whatsapp-icon, &__pwa-icon {
    width: 24px;
    height: 24px;
    max-width: 24px;

    cursor: pointer;

    margin-right: 8px;
  }

  &__link-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1100px) {
  .header {
    margin-bottom: 50px;

    &__logo {
      &_mobile {
        display: block;
      }

      &_desktop {
        display: none;
      }
    }

    &__link-icon {
      display: flex;
      flex-direction: row;

      .menu-cart {
        &__image {
          width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &__button-container {
      display: none;
    }

    &__link-group {
      display: flex;
      flex-direction: row;
    }

    &__logo-container {
      display: flex;
    }

    &__container {
      display: flex;
      align-items: flex-start;
      position: relative;
      z-index: 22;

      &_row-reverse {
        flex-direction: row;
      }
    }

    &__button-container {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__auth-container {
      margin-bottom: 16px;
    }

    &__help-container {
      width: 100%;
      justify-content: center;
    }

    &__telegram-container {
      margin-right: 0;
    }

    &__link-item{
      margin-top: 30px;

      .link {
        &__text {
          margin-left: 8px;
        }
      }
    }

    &__link-container {
      .link:first-child {
        margin-right: 12px;
      }
    }
    &__button-container {
      width: 100%;

      flex-direction: column;
      justify-content: space-between;
    }

    &__help-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      margin-bottom: 100px;
    }

    &__container_row-reverse {
      flex-direction: row;
    }

    &__container_row {
      flex-direction: row-reverse;
    }
  }
  .mobile-menu {
    display: none;

    &_active {
      display: flex;
      overflow-y: auto;
    }
  }
}

@media (max-width: 1250px) {
  .header {
    &__auth-container {
      flex-direction: column;
    }

    &__button {
      margin: 0 0 5px;
      height: 36px;
    }

    &__button-container {
      align-items: flex-start;
    }

    &__container {
      align-items: flex-start;
    }
  }
}


@media (max-width: 900px) {
  .header {
    margin-bottom: 30px;

    &__auth-container {
      width: 100%;

      flex-direction: column;
      align-items: flex-start;

      margin-bottom: 5px;

      .button{
        margin-right: 0 !important;
        margin-bottom: 5px;
      }
    }

    &__help-container {
      flex-direction: column;
      align-items: flex-start;

      margin: 0;
    }

    &__jivochat-container, &__telegram-container {
      margin-bottom: 5px;
      margin-right: 0;
    }

    &__link-container {
      flex-direction: column;

      .link:first-child {
        margin-right: 0;
      }
    }
  }
}


@media (max-width: 630px) {
  .header {
    position: fixed;
    width: 100vw;
    padding: 15px 0 10px;

    &__link-container {
      flex-direction: column;
    }

    &__logo {
      margin-bottom: 0;
    }

    &__container {
      &_row {
        align-items: center;
        position: relative;
        flex-direction: row;
      }
    }
  }
}

@media (max-width: 540px) {
  .header {
    margin-bottom: 20px;
  }
}

@media (max-width: 490px) {
  .header {
    &__container {
      &_row {
        align-items: center;
        flex-direction: row;
      }
    }

    &__auth-container {
      align-items: center;
      flex-direction: column;
    }

    &__link-container {
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}


@media (max-width: 460px) {
  .header {

    &__help-container {
      margin-bottom: 150px;
    }

    &__logo {
      width: 150px;
      margin: auto;

      &_mobile {
        max-width: 150px;
        
        display: block;
      }
    }
  }
}
