.phone-form {
  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    color: #1D1D2D;

    margin: 0 0 30px;
  }

  .default {
    &__check-rules {
      display: none;
    }
  }

  &__action-container {
    display: flex;
    flex-direction: column;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #5F5F6B;

    margin: 0 0 32px;
  }

  &__horizontal-mode {
    display: flex;
    justify-content: space-between;

    .button[data-mode='vertical'] {
      display: none;
    }
  }

  &__vertical-mode[data-mode='horizontal'] {
    display: none;
  }

  &__link-container {
    display: flex;
    align-items: flex-start;

    margin-bottom: 0px;
  }

  &__arrow {
    margin-right: 4px;
  }

  &__link {
    display: block;

    cursor: pointer;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #4661C4;

    &_bottom-margin {
      margin-bottom: 100px;
    }
  }
}

@media (max-width: 630px) {
  .phone-form {
    .button__text {
      font-size: 12px;
    }
  }
}
