.window-loading {
  width: 100%;
  height: 100%;
  min-height: 500px;

  display: none;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;

  padding-top: 150px;

  &_active {
    display: flex;

    animation: 0.1s open 0.2s ease-in forwards;
  }
}

@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}