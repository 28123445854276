.history-eko-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__phone {
    min-width: 145px;
  }

  &__info {
    min-width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
    background: #fff;
    align-items: center;
    border-bottom: 1.5px solid #f6f6f9;
  }

  &__nominal {
    justify-content: center;
    font-weight: 700;
  }

  &__date {
    justify-content: flex-end;
    width: 180px;
    text-align: end;
  }

  &__number {
    justify-content: flex-start;
    width: 118px;
  }

  &__text {
    font-size: 18px;
    color: #30303A;

    &_desktop {
      display: block;
    }

    &_mobile {
      display: none;
    }
  }
}

@media (max-width: 820px) {
  .history-eko-item {
    &__info-number {
      width: 90px;
    }

    &__date {
      width: 53px;
    }

    &__phone {
      min-width: 110px;
    }

    &__info {
      min-width: 45px;
    }

    &__text {
      font-size: 12px;

      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }
  }
}

@media (max-width: 500px) {
  .history-eko-item {
    &__text {
      font-size: 12px;
    }
  }
}
