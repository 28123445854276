.balloon  {
  position: absolute;
  animation: linear 0s infinite normal forwards balloonFly;
  filter: blur(1);

  &__front {
    transform: scale(1.2);
  }

  &__middle {
    transform: scale(1);
  }

  &__back {
    transform: scale(0.8);
  }
}

.container {
  background-color: #f6f6f9;
  width: 100%;
  height: 100vh;
}

.gift {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 400px;
  min-height: 400px;
  z-index: 5;
  margin-top: 200px;

  &__logo-container {
    position: fixed;
    opacity: 0;
    margin-top: 100px;
    z-index: 4;
    display: flex;
    justify-content: center;
    width: 100%;

    &_show {
      animation: 0.5s linear 1s 1 normal forwards showItem;
    }

    &_hide {
      animation: 0.3s linear 0s 1 normal forwards hideItem;
    }
  }

  &__logo {
    max-width: 100%;
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  &__hue-rotate {
    filter: hue-rotate(225deg);
  }

  &__box-image {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  &__box  {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 95%;
    height: 117px;
  }

  &__cap  {
    z-index: 9;
    width: 100%;
    animation: 1s linear 2s infinite normal forwards shake;
  }

  &__drop  {
    top: 0px;
    animation: 2s cubic-bezier(0,-1.97, 1,-1.97) 0s 1 normal forwards dropGift;
  }


  &__present-list {
    height: 50px;
    width: 90%;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    border-radius: 10px;
    display: none;

    &_animate {
      display: block;
      height: 50px;
      width: 90%;
      background-color: #fff;
      animation: 1s linear 0s 1 normal forwards openPresentList;
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    overflow-y: auto;
    height: 80vh;
    flex-direction: column;

    &_show-content {
      animation: 1s linear 1s 1 normal forwards showItem;
    }
  }

  .open  {
    animation: 1s linear 0s 1 normal forwards openGift;
  }

  .hide-box  {
    animation: 1s linear 0s 1 normal forwards hideBox;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__gift-button {
    width: 120px;
    height: 50px;
    position: absolute;
    border-radius: 10px;
    background-color: #61c368;
    opacity: 0;
    color:#fff;
    font-weight: 700;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    font-family: sans-serif;
    line-height: 1;
    transition: 0.5s;
    z-index: 5;

    &_show {
      animation: 0.5s linear 1s 1 normal forwards showItem;
    }

    &_hide {
      animation: 0.3s linear 0s 1 normal forwards hideItem;
    }

    &:hover {
      background-color: #4d9852;
    }
  }
}

.sert {
  &__container{
    min-width: 45%;
    max-width: 90%;
    flex: 1;
    min-height: 100px;
    border: 1px solid #eee;
    margin: 20px 10px 10px;
    border-radius: 12px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #ffffff;

    &:hover  {
      box-shadow: 3px 2px 5px 0px #e3e1e1;
    }
  }

  &__number {
    font-family: sans-serif;
    text-align: center;
    margin: 20px;
  }

  &__image {
    width: 90%;
    margin: 5%;
    border-radius: inherit;
    border: 1px solid #F1F2F9;
  }

  &__title {
    font-family: sans-serif;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__nominal {
    font-family: sans-serif;
    max-width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 5px;
    font-size: 22px;
    font-weight: 700;
    margin: 5px;
  }

  &__card-number {
    color: #acaccc;
    font-size: 10px;
  }

  &__point {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: #4661C4;
    margin-right: 5px;
  }

  &__button {
    width: auto;
    height: 38px;
    display: flex;
    font-weight: 600;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    line-height: 1;
    text-align: center;
    margin: 15px auto;
    padding: 0 15px;
    border-radius: 5px;

    &_fulfilled {
      background-color: #4661C4;
      color:#fff;
      transition: all ease-in-out 0.2s;
      cursor: pointer;

      &:hover {
        background-color: #5978e3;
      }
    }

    &_pending {
      background-color: #fff;
      color:#4661C4;
    }
  }
}

@media (max-width: 750px) {
  .gift {
    width: 50%;

    &__box {
      height: calc(50vw / 2.9007);;
    }
  }
}

@media (max-width: 670px) {
  .gift {
    width: 90%;

    &__box {
      height: calc(90vw / 2.9007);;
    }
  }
  .sert {
    &__container {
      flex-wrap: wrap;
      flex-direction: row;
    }

    &__image {
      width: 50%;
    }

    &__title {
      width: 50%;
      &_font-size {
        font-size: 14px;
      }
    }

    &__number {
      display: none;
    }

    &__button {
      font-size: 12px;
      width: 90%;
      height: 35px;
    }
  }
}

@keyframes balloonFly {
  100% {
    top: -200%;
  }
}

@keyframes mainBalloonFly {
  0% {
    transform: translateY(200vh);
  }
  100% {
    transform: translateY(-400vh);
  }
}

@keyframes showItem {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideItem {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes openPresentList {
  0% {
    transform: translateY(-100px);
    width: 100px;
    height: 50px;
    z-index: 3;
  }
  50% {
    transform: translateY(-100px);
    width: 100px;
    height: 50px;
    z-index:3;
  }
  90% {
    transform: translateY(-100px);
    width: 150%;
    max-width: 90vw;
    height: 95vh;
    z-index:15;
    border: none;
  }
  100% {
    transform: translateY(-200px);
    width: 150%;
    max-width: 90vw;
    height: 80vh;
    z-index:15;
    border:1px solid #d7d7d7;

    .gift__logo-container {
      display: none;
    }
  }
}

@keyframes openGift {
  0% {
    transform: translate(0px,0px);
  }
  100% {
    transform: translate(0px,-500px);
    opacity: 0;
  }
}

@keyframes hideBox {
  0% {
    transform: translate(0px,0px);
  }
  100% {
    transform: translate(0px,500px);
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: translate(0px,0px) rotate(0deg);
  }
  10% {
    transform: translate(0px,-10px) rotate(-5deg);
  }
  20% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  40% {
    transform: translate(0px,-10px) rotate(5deg);
  }
  50% {
    transform: translate(0px,0px) rotate(0deg);
  }
  100% {
    transform: translate(0px,0px) rotate(0deg);
  }
}
