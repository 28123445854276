.timer-left-out {
  &__container {
    width: 370px;

    text-align: center;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #FF4B40;

    margin: 0 0 16px;
  }

  &__description {
    font-size: 20px;
    line-height: 26px;
    color: #2D2B37;

    margin: 0 0 16px;
  }
}

@media (max-width: 768px) {
  .timer-left-out {
    &__popup-content {
      width: 100%;
      height: 100%;

      align-items: center;
    }
  }
}

@media (max-width: 510px) {
  .timer-left-out {
    &__container {
      width: 330px;
    }

    &__title {
      font-size: 22px;
    }

    &__description {
      font-size: 16px;
    }
  }
}

@media (max-width: 440px) {
  .timer-left-out {
    &__container {
      width: 300px;
    }

    &__title {
      font-size: 20px;
    }

    &__description {
      font-size: 14px;
    }
  }
}

@media (max-width: 330px) {
  .timer-left-out {
    &__title {
      font-size: 16px;
    }

    &__description {
      font-size: 12px;
    }
  }
}
