@font-face {
  font-family: 'Circe';
  src: url('../../../../../../../../assets/fonts/Circe-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
.select-step-nominals-2 {
  width: 100%;
  background: #4661C4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: height 300ms linear;

  &__error {
    height: 20px;
    color: #f00;
    font-size: 12px;
  }

  &__title {
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 3px;
  }

  &__input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  &__nominal {
    margin-right: 0 !important;
  }

  .second-input {
    &__input {
      height: 35px;
      width: calc(100% - 20px);
    }

    &__container {
      text-align: left;
    }
  }

  &__step {
    color: #FFFFFF;
    font-size: 10px;
    width: 100%;
    text-align: left;
  }

  &__form-container {
    .button {
      background: #FFFFFF;
      height: 40px;
      width: calc(100% - 20px);
      padding: 12px;
      border-radius: 8px;

      &__text {
        color: #4661C4;
        font-family: 'Circe';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin-right: 8px;
      }
    }
  }

  &__qty {
    margin-right: 0 !important;
  }

  &__subtitle {
    font-size: 11px;
    color: #fff;
  }

  &__form-title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__show {
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 600ms;
    animation-name: showForm;
  }

  &__hide {
    display: none;
  }

  &__active {
    height: 141px;
    transition: all 300ms linear;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

@media (max-width: 460px) {
  .select-step-nominals-2 {
    &__active {
      height: 160px;
    }
  }
}

@media (max-width: 360px) {
  .select-step-nominals {
    &__image-card {
      margin-top: -20px;
    }
  }
}

@media (max-width: 20px) {
  .select-step-nominals {
    &__form {
      &_animation {
        height: 200px;
      }
    }

    &__input-container {
      flex-direction: column;
    }

    &__nominal {
      margin-right: 0 !important;
      margin-bottom: 8px;
    }
  }
}

@keyframes showForm {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
