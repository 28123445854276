.search {
  position: relative;

  &__input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #B0B7D3;
    border-radius: 8px;
    background-color: #fff;

    padding: 10px 16px;
  }

  &__input {
    width: 100%;
    font-size: 16px;
    line-height: 150%;
    color: #1D1D2D;

    border: none;
    outline: none;

    &::placeholder {
      color: rgba(48, 48, 58, 0.5);
    }
  }

  &__icon {
    cursor: pointer;

    margin-left: 20px;
  }

  &__result {
    font-size: 12px;
    line-height: 150%;
    color: #F45384;

    transition: opacity 0.3s linear;

    position: absolute;
    bottom: -20px;

    opacity: 0;

    &_visible {
      opacity: 1;
    }
  }
}

@media (max-width: 660px) {
  .search {
    &__input {
      font-size: 14px;
    }

    &__icon {
      width: 20px;
    }
  }
}