.go-back-button {
  &__go-back {
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 75px;
    cursor: pointer;
    font-size: 16px;
    color: #6F6F76;
    margin-bottom: 15px;
  }
}

@media (max-width: 820px) {
  .go-back-button {
    &__go-back {
      display: flex;
    }
  }
}
