.commission-item {
  &__content {
    display: flex;
    justify-content: space-between;

    font-size: 18px;
    color: #30303A;

    margin: 0 0 8px;

    &:last-child {
      margin: 0;
    }
  }
  
  &__markup {
    color: #F45384;

    white-space: nowrap;
  }
}